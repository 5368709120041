import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import Breadcrumbs from "../Components/Breadcrumbs";
import Footer from "../Components/Footer";
import FormBuilder from "../Components/FormBuilder";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const FIELDS = [
  {
    label: "Tipo di intervento",
    name: "macroservices",
    type: "select",
    mandatory: true,
  },
  {
    label: "Servizio richiesto",
    name: "services",
    type: "select",
    mandatory: true,
  },
];

const FIELDS_MULTI = [
  {
    type: "blocks",
    submitLabel: "Aggiungi Servizio",
    name: "services",
    size: 2,
    fields: [
      {
        label: "Macroservizio",
        name: "macroservices",
        type: "select",
        mandatory: true,
      },
      {
        label: "Servizio",
        name: "services",
        type: "select",
        mandatory: true,
      },
    ],
  },
];

const DUMMY_OPTIONS = {
  macroservices: [
    { label: "Macro Servizio 1", value: 1 },
    { label: "Macro Servizio 2", value: 2 },
  ],
  services: [
    { label: "Servizio 1", value: 1 },
    { label: "Servizio 2", value: 2 },
  ],
};

const OfferedServices = (props) => {
  // const [options, setOptions] = useState(DUMMY_OPTIONS);
  const [options, setOptions] = useState({
    macroservices: [],
    services: [],
  });
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const populateMacroservices = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_HOST}wideer/m/`
        );
        setOptions((p) => ({
          ...p,
          macroservices: response.data?.map((m) => ({
            label: m.macro_service,
            value: m.id,
          })),
        }));
      } catch (error) {
        console.log(`error macroservices: ${error}`);
      }
    };
    populateMacroservices();
  }, []);

  const handleChange = async (formData) => {
    try {
      if (!formData.macroservices?.[0]) return;
      const response = await axios.get(
        `${process.env.REACT_APP_API_HOST}wideer/get-services/${formData.macroservices?.[0]}/`
      );
      setOptions((p) => ({
        ...p,
        services: response.data?.map((m) => ({
          label: m.service,
          value: m.id,
        })),
      }));
    } catch (error) {
      console.log(`error services: ${error}`);
    }
  };

  const handleSubmit = (formData) => {
    if (!formData) return;
    const services =
      searchParams.get("registering") === "true"
        ? formData.services.reduce((acc, curr) => {
            return {
              macroservices: [
                ...(acc.macroservices || []),
                ...curr.macroservices,
              ],
              services: [...(acc.services || []), ...curr.services],
            };
          }, {})
        : formData;
    if (searchParams.get("type") === "searching") {
      navigate(
        `/questions-tree?service=${formData.services?.[0]}&macro_service=${
          formData.macroservices?.[0]
        }&rtype=${searchParams.get("rtype")}`
      );
    }
    if (
      searchParams.get("type") === "professional" ||
      searchParams.get("type") === "artisan"
    ) {
      navigate(
        `/display-name?type=${searchParams.get("type")}&registering=true`,
        {
          state: {
            formData: {
              ...services,
              ...location.state.formData,
            },
          },
        }
      );
    }
  };

  return (
    <div className="min-vh-100 d-flex flex-column">
      <Header />
      <Breadcrumbs />
      <div className="container mt-4 h-100">
        <div className="h-100">
          <div className="seekng_job_title mb-4">
            <h2>
              Ho bisogno di un{" "}
              {searchParams.get("type") === "professional"
                ? "Professionista"
                : searchParams.get("type") === "artisan"
                ? "Artigiano"
                : "Servizio"}
            </h2>
          </div>
          <FormBuilder
            fields={
              searchParams.get("registering") === "true" ? FIELDS_MULTI : FIELDS
            }
            onSubmit={handleSubmit}
            onChange={handleChange}
            submitLabel="Avanti"
            onBack={() => navigate(-1)}
            selectOptions={options}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return { candidate: state.candidate };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OfferedServices);
