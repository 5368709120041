import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    requestPurchageTokenPayment,
    userLogout,
} from "../../Redux/actions";
import { toast } from 'react-toastify';
import axios from 'axios';


function SuccessPayment(props) {
    const query = new URLSearchParams(window.location.search);
    const token_amount = query.get("token_amount");
    const productName = query.get("productName");
    const order_id = query.get("order_id");
    const token = localStorage.getItem("token");
    const navigate = useNavigate();
    useEffect(() => {
        if(token_amount && productName) {
            
            const updateToken = props.requestPurchageTokenPayment({
                token: token,
                data: {
                    bought: parseInt(token_amount),
                    in: productName,
                    order_id: order_id
                }
            });
            console.log(updateToken);
            navigate('/company/Creditmanagement');
        }
    }, []);
    
}

const mapStateToProps = (state) => {
    return { company: state.company };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({
        requestPurchageTokenPayment,
        userLogout
    }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SuccessPayment);