// File: /src/Components/AdsBuilder.js
// Created Date: Thursday December 19th 2024
// Author: Christian Nonis <alch.infoemail@gmail.com>
// -----
// Last Modified: Thursday December 19th 2024 10:29:53 am
// Modified By: the developer formerly known as Christian Nonis at <alch.infoemail@gmail.com>
// -----

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetTranslationOr } from "../utils/translations";
import { killSnakeMakeWord } from "../utils/words";

export const getNestedValue = (obj, path) => {
  return path.split(".").reduce((acc, part) => acc?.[part], obj);
};

const fields = (ad, fields) => {
  return Object.fromEntries(
    Object.entries(ad).filter(([key, _]) => {
      if (
        fields.some((field) => typeof field === "object" && field.key === key)
      ) {
        return true;
      }
      return fields.includes(key);
    })
  );
};

const tagBuilder = (ad, tags) => {
  return Object.entries(fields(ad, tags)).map((tag) => {
    return tag;
  });
};

const values = (fields) => {
  return Object.values(fields);
};

const AdsBuilder = ({
  ads,
  cardFields,
  detailedFields,
  blockFields,
  onAction,
  actionLabel,
  openedDetail,
}) => {
  const [detailedAd, setDetailedAd] = useState();

  const getTranslationOr = useGetTranslationOr();

  useEffect(() => {
    if (openedDetail) {
      setDetailedAd(
        ads.find(
          (ad) =>
            ad[openedDetail.targetKey] === openedDetail.targetValue &&
            !!openedDetail.targetKey &&
            !!ad[openedDetail.targetKey]
        )
      );
    }
  }, [openedDetail, ads]);

  return (
    <div className="d-flex flex-column gap-2 my-4">
      {detailedAd ? (
        <DetailedAd
          ad={detailedAd}
          fields={detailedFields}
          blockFields={blockFields}
          onClose={() => setDetailedAd(null)}
          onAction={onAction}
          actionLabel={actionLabel}
        />
      ) : (
        <></>
      )}
      {ads.map((ad, i) => {
        return (
          <div
            key={i}
            className="p-4 position-relative"
            style={{
              borderColor: "#e7552090",
              borderWidth: "1.8px",
              borderStyle: "solid",
              borderRadius: "10px",
              cursor: "pointer",
            }}
            onClick={() => setDetailedAd(ad)}
          >
            <p className="position-absolute top-0 end-0 p-2 mt-2 me-3 small">
              {new Date(ad[cardFields.date])?.toLocaleDateString()}
            </p>
            <h3
              style={{
                fontWeight: 600,
                fontSize: "22px",
                lineHeight: "30px",
                color: "#081828",
                textTransform: "capitalize",
                position: "relative",
                display: "flex",
                flexDirection: "row",
                gap: "0.5rem",
              }}
            >
              {values(fields(ad, cardFields.title1)).map((value, i) => {
                return <span key={i}>{value}</span>;
              })}
            </h3>
            <h4
              style={{
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "24px",
                color: "#081828",
                display: "flex",
                flexDirection: "row",
                gap: "0.5rem",
              }}
            >
              {values(fields(ad, cardFields.title2)).map((value, i) => {
                if (value instanceof Date) {
                  return <span key={i}>{value.toLocaleDateString()}</span>;
                }
                return <span key={i}>{value}</span>;
              })}
            </h4>
            <p
              style={{
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "22px",
                color: "#626262",
                display: "flex",
                flexDirection: "row",
                gap: "0.5rem",
                marginTop: "0.5rem",
              }}
            >
              {values(fields(ad, cardFields.subtitle)).map((value, i) => {
                return <span key={i}>{value}</span>;
              })}
            </p>
            <ul className="d-flex flex-row gap-2 mt-2">
              {cardFields.highlighted_elements?.map((element, i) => {
                if (element.dependencies?.length) {
                  for (const dependency of element.dependencies) {
                    if (
                      getNestedValue(ad, dependency.key) !== dependency.value
                    ) {
                      return <></>;
                    }
                  }
                }
                return (
                  <li
                    key={i}
                    className="d-flex flex-column bg-light rounded-3 overflow-hidden"
                    style={{
                      width: "fit-content",
                    }}
                  >
                    <span className="text-muted small px-3 pt-2">
                      {element.label}
                    </span>
                    <span className="px-3 pb-2 fs-6 fw-medium">
                      {ad[element.key]}
                    </span>
                  </li>
                );
              })}
            </ul>
            <ul className="d-flex flex-row gap-2 pl-0 mt-2">
              {tagBuilder(ad, cardFields.tags).map(([key, value], i) => {
                if (typeof value === "boolean" && !value) return <></>;
                if (typeof value !== "boolean" && !value?.length) return <></>;
                let element = (
                  <li
                    className="px-3 py-1"
                    style={{
                      fontSize: "12px",
                      lineHeight: "22px",
                      borderRadius: "6px",
                      fontWeight: 500,
                      ...(typeof value === "boolean"
                        ? {
                            backgroundColor: "#e75520",
                            color: "#fff",
                          }
                        : {
                            backgroundColor: "#ececec",
                            color: "#000",
                          }),
                    }}
                    key={i}
                  >
                    {getTranslationOr(key, killSnakeMakeWord(key))}
                  </li>
                );
                if (i < tagBuilder(ad, cardFields.tags).length - 1) {
                  element = (
                    <div
                      key={i}
                      className="d-flex flex-row gap-2 align-items-center"
                    >
                      {element}
                    </div>
                  );
                }
                return element;
              })}
            </ul>
          </div>
        );
      })}
    </div>
  );
};

const DetailedAd = ({
  ad,
  fields: _fields,
  onClose,
  blockFields,
  onAction,
  actionLabel,
}) => {
  const getTranslationOr = useGetTranslationOr();
  return (
    <div
      className="position-fixed top-0 start-0 w-100 h-100"
      style={{
        zIndex: 1000,
      }}
    >
      <div
        className="position-absolute top-0 start-0 w-100 h-100 bg-black opacity-50"
        onClick={onClose}
      ></div>
      <div
        style={{
          width: "45%",
          height: "100%",
          marginLeft: "auto",
          minWidth: "40rem",
          maxWidth: "50rem",
          backgroundColor: "#fff",
          position: "relative",
          paddingBlock: "4rem",
          paddingInline: "1.5rem",
        }}
      >
        <div
          className="position-absolute top-0 start-0 p-4"
          style={{ cursor: "pointer" }}
          onClick={onClose}
        >
          <button className="btn btn-outline-secondary mt-2" onClick={onClose}>
            Chiudi
          </button>
        </div>
        {onAction && actionLabel ? (
          <div className="position-absolute top-0 end-0 p-4">
            <button
              className="btn btn_default btn_orange_outline"
              onClick={() => onAction(ad)}
            >
              {actionLabel}
            </button>
          </div>
        ) : (
          <></>
        )}
        <h4 style={{ marginTop: "2rem" }} className="fw-bold">
          Dettagli
        </h4>
        <div className="d-flex flex-row align-items-center gap-2 mt-4">
          {_fields.image?.length ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                border: "1px solid #ccc",
                width: "5rem",
                height: "5rem",
                borderRadius: "100rem",
                overflow: "hidden",
                backgroundColor: "#e75520df",
              }}
            >
              {_fields.image?.length ? (
                <img src={ad[_fields.image]} alt="ad" />
              ) : (
                <p className="text-white fs-4">
                  {values(fields(ad, _fields.title1))
                    .flatMap((v) => v?.split(" ") ?? [])
                    .map((v) => v[0])
                    .join("")}
                </p>
              )}
            </div>
          ) : (
            <></>
          )}
          {_fields.title1?.length ? (
            <p className="fs-4 ms-2">{values(fields(ad, _fields.title1))}</p>
          ) : (
            <></>
          )}
        </div>
        {_fields.subtitle?.length ? (
          <h4 className="mt-4 fs-6">{values(fields(ad, _fields.subtitle))}</h4>
        ) : (
          <></>
        )}
        {_fields.description?.length ? (
          <p className="my-4">{values(fields(ad, _fields.description))}</p>
        ) : (
          <></>
        )}
        <ul className="d-flex flex-row gap-2">
          {_fields.highlighted_elements?.map((element, i) => {
            return (
              <li
                key={i}
                className="d-flex flex-column bg-light rounded-3 overflow-hidden"
                style={{
                  width: "fit-content",
                }}
              >
                <span className="text-muted small px-3 pt-2">
                  {element.label}
                </span>
                <span className="px-3 pb-2 fs-6 fw-medium">
                  {ad[element.key]}
                </span>
              </li>
            );
          })}
        </ul>
        <ul className="d-flex flex-column gap-1 mt-2">
          {Object.entries(fields(ad, _fields.highlights)).map(
            ([key, _value], i) => {
              let value = _value;
              if (typeof value === "boolean") {
                value = value ? "Si" : "No";
              }
              if (value instanceof Date) {
                value = value?.toLocaleDateString();
              }
              return (
                <li
                  key={i}
                  className="d-flex flex-row gap-2 align-items-center"
                >
                  <span
                    style={{
                      width: "0.5rem",
                      height: "0.5rem",
                      borderRadius: "100rem",
                      backgroundColor: "#e75520a0",
                      display: "inline-block",
                    }}
                  ></span>
                  <span className="fw-bold">
                    {getTranslationOr(key, killSnakeMakeWord(key))}
                  </span>
                  <span
                    className=""
                    style={{
                      fontSize: "13px",
                      color: "#626262",
                    }}
                  >
                    {value}
                  </span>
                </li>
              );
            }
          )}
        </ul>
        <hr className="my-4" />
        <ul className="d-flex flex-column gap-3">
          {values(fields(ad, _fields.blocks))
            .flat()
            .map((block, i) => {
              return (
                <li key={i}>
                  <p className="fw-bold fs-6">{block[blockFields.title]}</p>
                  <p className="fs-10 text-muted">
                    {block[blockFields.content]}
                  </p>
                </li>
              );
            })}
        </ul>
        {values(fields(ad, _fields.blocks)).flat().length ? (
          <hr className="my-4" />
        ) : (
          <></>
        )}
        {_fields.info ? (
          <div>
            <h4 className="fw-bold fs-6">Info</h4>
            <ul className="d-flex flex-column gap-1 mt-2">
              {Object.entries(fields(ad, _fields.info)).map(
                ([_key, _value], i) => {
                  let key = typeof _value === "object" ? _value.key : _key;
                  let value =
                    _value ||
                    _fields.info.find((field) => field.key === key)
                      ?.nullable_message;
                  if (typeof value === "boolean") {
                    value = value ? "Si" : "No";
                  }
                  if (value instanceof Date) {
                    value = value?.toLocaleDateString();
                  }
                  return (
                    <li
                      key={i}
                      className="d-flex flex-row gap-2 align-items-center"
                    >
                      <span
                        style={{
                          width: "0.5rem",
                          height: "0.5rem",
                          borderRadius: "100rem",
                          backgroundColor: "#e75520a0",
                          display: "inline-block",
                        }}
                      ></span>
                      <span className="fw-bold">
                        {getTranslationOr(key, killSnakeMakeWord(key))}
                      </span>
                      <span
                        className=""
                        style={{
                          fontSize: "13px",
                          color: "#626262",
                        }}
                      >
                        {value}
                      </span>
                    </li>
                  );
                }
              )}
            </ul>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default AdsBuilder;
