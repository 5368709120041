import React, { useEffect } from 'react'
import Header from '../../../Components/Header'
import Footer from '../../../Components/Footer'
import Select, { createFilter, components } from 'react-select'
import PhoneInput from 'react-phone-input-2'
import it from 'react-phone-input-2/lang/it.json'
import ToggleButtonControl from './ToggleButtonControl'
import AddressControl from './AddressControl'
import SelectLocation from './SelectLocation'
import NextButton from '../common/NextButton'
import PreviousAdvertisment from './PreviousAdvertisment'
import IndirizzoField from './IndirizzoField'
import CreatableSelect from 'react-select/creatable'
import loaderLogo from "../../../images/Iphone-spinner-2.gif";

const Input = props => <components.Input {...props} isHidden={false} />

const FormField = ({ label, error, children, className = 'col-12' }) => (
  <div className={className}>
    <label className='form-label'>{label}</label>
    {children}
    {error && (
      <div style={{ color: '#D10000', padding: '3px 10px', width: '100%' }}>
        {error}
      </div>
    )}
  </div>
)

const ConditionalField = ({ show, children }) => (
  <div className='col-12' style={{ visibility: show ? 'visible' : 'hidden' }}>
    {children}
  </div>
)

const FormSection = ({ left, right }) => (
  <div className='seeking_job_form'>
    <div className='seeking_form_left'>
      <div className='row g-3'>{left}</div>
    </div>
    <div className='seeking_form_right'>
      <div className='row g-3'>{right}</div>
    </div>
  </div>
)

const CreaAnnuncioOne = props => {
  const {
    nextStep,
    CreatAd_One,
    errorCreatAd_One,
    setCreatAd_One,
    setErrorCreatAd_One,
    sortedCountryData,
    municipalities,
    recruitmentTimeline,
    countryRef,
    provinceRef,
    emailRef,
    addemailRef,
    cityRef,
    phoneRef,
    streetRef,
    handleOnCreatAd_One,
    handleOnCreatAdJobTitle,
    handlenContactPerson,
    handleChangeCityCreatAd_One,
    handleOnCreatNumberOfRoles,
    handleOnDadeLineDate,
    handlePhoneChange,
    handleOnGetAddress,
    isLoading,
    validateEmail,
    validateCountry,
    validateCity,
    validateProvince,
    validateZipCode,
    validatePhone,
    validateAddEmail,
    validateJobTitle,
    validateNumberOfRoles,
    validateStreetNumber,
    validateAdExpDate,
    validateTimelinePosition,
    handleOnPreviousAdvertisement,
    contactpersonValidate,
    error,
    setError,
    mystyle,
    isClearable,
    isSearchable,
    filterConfig,
    t,
    jobList,
    previousAdd,
    representative,
    previousAddRef,
    jobTitleRef,
    rolesSoughtRef,
    zipCodeRef,
    contactPersonRef,
    applicationSendingDeadlineRef,
    recruitmentTimeRef,
    CreatAd_One_Loader,
    addressDetails,
    handleOnAddressSelect,
    newAdvertisementId,
    adLoader
  } = props

 

  useEffect(() => {
    const focusField = () => {
      const fieldRefs = {
        job_title: jobTitleRef,
        country: countryRef,
        city: cityRef,
        province: provinceRef,
        contact_person: contactPersonRef,
        n_roles_sought: rolesSoughtRef,
        street: streetRef,
        zip_code: zipCodeRef,
        application_sending_deadline: applicationSendingDeadlineRef,
        recruitment_time: recruitmentTimeRef
      }

      for (const [field, ref] of Object.entries(fieldRefs)) {
        if (errorCreatAd_One[field] && ref?.current) {
          ref.current.focus()
          break
        }
      }
    }

    if (error) {
      focusField()
      setError(false)
    }
  }, [error, errorCreatAd_One])

  const renderHeader = () => (
    <div className='seekng_job_title_note'>
      <div className='seekng_job_title'>
        <h2>
          {newAdvertisementId != null
            ? "Modifica l'annuncio"
            : props.ad_type == 'agent'
            ? 'Inserisci un nuovo annuncio per la ricerca di agenti'
            : 'Inserisci un nuovo annuncio per la ricerca di dipendenti'}
        </h2>
        <h6>Informazioni generali</h6>
      </div>
    </div>
  )

  const renderJobTitleSection = () => (
    
    <FormField
      label="Posizione ricercata (Titolo dell'annuncio)"
      error={CreatAd_One?.job_title?.length === 0 && errorCreatAd_One.job_title}
    >
      <CreatableSelect
        className={`form-control select_dropdown ${
          CreatAd_One?.job_title?.length === 0 && errorCreatAd_One.job_title
            ? 'error'
            : ''
        }`}
        placeholder='Seleziona una posizione o creane una nuova'
        value={
          CreatAd_One.job_title
            ? { label: CreatAd_One.job_title, value: CreatAd_One.job_title }
            : ''
        }
        onChange={handleOnCreatAdJobTitle}
        isLoading={!isLoading}
        onBlur={validateJobTitle}
        options={props.ad_type === 'agent' ? jobList.filter(job => job.data?.ad_type?.type === 'Agente') : jobList }
        ref={jobTitleRef}
        isClearable={isClearable}
        isSearchable={isLoading && isSearchable}
        filterOption={createFilter(filterConfig)}
        components={{ Input }}
      />
    </FormField>
  )

  const renderReferentSection = () => (
    <FormSection
      left={
        <FormField
          label='Referente interno'
          error={
            CreatAd_One?.contact_person?.length === 0 &&
            errorCreatAd_One.contact_person
          }
        >
          <Select
            className={`form-control select_dropdown ${
              CreatAd_One?.contact_person?.length === 0 &&
              errorCreatAd_One.contact_person
                ? 'error'
                : ''
            }`}
            placeholder='Seleziona utente'
            value={representative.find(
              option => option.value === CreatAd_One.contact_person
            )}
            ref={contactPersonRef}
            onChange={handlenContactPerson}
            //onBlur={contactpersonValidate}
            options={representative}
            isClearable={isClearable}
            isSearchable={isSearchable}
          />
        </FormField>
      }
      right={
        <>
          <FormField label="Invia gli aggiornamenti per questo annuncio all'indirizzo">
            <input
              type='email'
              className='form-control'
              placeholder={t('register.email')}
              id='email_for_updates'
              name='email_for_updates'
              ref={emailRef}
              value={CreatAd_One.email_for_updates || ''}
              readOnly
            />
          </FormField>
          <div className='col-12'>
            <div className='selection_checkboxs row'>
              <div className='selection_checkboxs_col col-12'>
                <div
                  className='selection_checkbox m-0'
                  onClick={() => {
                    setCreatAd_One(prevstate => ({
                      ...prevstate,
                      updates_by_email: !CreatAd_One?.updates_by_email
                    }))
                    CreatAd_One?.updates_by_email === true &&
                      setErrorCreatAd_One(preverr => ({
                        ...preverr,
                        updates_by_email: ''
                      }))
                  }}
                >
                  <input
                    type='checkbox'
                    readOnly
                    checked={CreatAd_One?.updates_by_email}
                  />
                  <label>
                    Invia un aggiornamento per e-mail anche all'arrivo di ogni
                    candidatura
                  </label>
                </div>
                {CreatAd_One?.updates_by_email === false &&
                  errorCreatAd_One.updates_by_email && (
                    <div style={mystyle}>
                      {errorCreatAd_One.updates_by_email}
                    </div>
                  )}
              </div>
            </div>
          </div>
        </>
      }
    />
  )

  const renderContactSection = () => (
    <FormSection
      left={
        <ToggleButtonControl
          CreatAd_One={CreatAd_One}
          setCreatAd_One={setCreatAd_One}
          errorCreatAd_One={errorCreatAd_One}
          setErrorCreatAd_One={setErrorCreatAd_One}
          mystyle={mystyle}
        />
      }
      right={
        <>
          <ConditionalField show={CreatAd_One.allow_phone_contact === true}>
            <FormField label='Numero tel. del referente'>
              <PhoneInput
                className='form-control p-0 numero_cellulare'
                name='cellphone'
                country={'it'}
                localization={it}
                value={CreatAd_One?.cellphone || ''}
                ref={phoneRef}
                placeholder={t('register.cellphone')}
                enableLongNumbers={18}
                disabled={true}
              />
            </FormField>
          </ConditionalField>

          <ConditionalField show={CreatAd_One.allow_e_mail_contact === true}>
            <FormField label='E-mail referente'>
              <input
                type='email'
                className='form-control'
                placeholder={t('register.email')}
                id='e_mail'
                name='e_mail'
                ref={addemailRef}
                value={CreatAd_One.e_mail || ''}
                readOnly
              />
            </FormField>
          </ConditionalField>

          <ConditionalField show={CreatAd_One.is_exp_date === true}>
            <FormField
              label='Data termine ultimo per invio'
              error={errorCreatAd_One.application_sending_deadline}
            >
              <input
                className={`form-control w-auto ${
                  errorCreatAd_One.application_sending_deadline ? 'error' : ''
                }`}
                type='date'
                name='application_sending_deadline'
                id='application_sending_deadline'
                value={CreatAd_One.application_sending_deadline || ''}
                onBlur={validateAdExpDate}
                ref={applicationSendingDeadlineRef}
                onChange={handleOnDadeLineDate}
                placeholder={t('register.birth_date')}
              />
            </FormField>
          </ConditionalField>

          <FormField
            label='Tempistiche di reclutamento per questa posizione'
            error={
              CreatAd_One?.recruitment_time?.length === 0 &&
              errorCreatAd_One.recruitment_time
            }
          >
            <CreatableSelect
              className={`form-control select_dropdown ${
                CreatAd_One?.recruitment_time?.length === 0 &&
                errorCreatAd_One.recruitment_time
                  ? 'error'
                  : ''
              }`}
              value={recruitmentTimeline.find(
                option => option.label === CreatAd_One.recruitment_time
              )}
              onChange={value => {
                setCreatAd_One(prev => ({
                  ...prev,
                  recruitment_time: value?.label ? value?.label : ''
                }))
              }}
              ref={recruitmentTimeRef}
              onBlur={validateTimelinePosition}
              options={recruitmentTimeline}
              isClearable={isClearable}
              isSearchable={isSearchable}
              placeholder='Seleziona una tempistica o creane una nuova'
            />
          </FormField>
        </>
      }
    />
  )

  return (
    <>
      <Header />
      <div className='seekng_job_sec create-an-ad'>
        <div
          className='container position-relative'
          style={{ minHeight: 'calc(100vh - 210px)' }}
        >
          {adLoader ? <div className='loader'>
            <div className='loader_inner'>
              <img src={loaderLogo} />
            </div>
          </div> : (
            <>

          <form>
            <PreviousAdvertisment
              CreatAd_One={CreatAd_One}
              handleOnPreviousAdvertisement={handleOnPreviousAdvertisement}
              previousAdd={previousAdd}
              isClearable={isClearable}
              isSearchable={isSearchable}
              previousAddRef={previousAddRef}
              filterConfig={filterConfig}
              mystyle={mystyle}
              CreatAd_One_Loader={CreatAd_One_Loader}
            />

            {renderHeader()}

            <div className='seeking_job_inner'>
              <FormSection
                left={
                  <>
                    {renderJobTitleSection()}
                    {!CreatAd_One.isAddressComplete && (
                      <IndirizzoField
                        CreatAd_One={CreatAd_One}
                        errorCreatAd_One={errorCreatAd_One}
                        addressDetails={addressDetails}
                        isClearable={isClearable}
                        isSearchable={isSearchable}
                        filterConfig={filterConfig}
                        handleOnAddressSelect={handleOnAddressSelect}
                        mystyle={mystyle}
                      />
                    )}
                    <SelectLocation
                      CreatAd_One={CreatAd_One}
                      errorCreatAd_One={errorCreatAd_One}
                      sortedCountryData={sortedCountryData}
                      municipalities={municipalities}
                      handleOnCreatAd_One={handleOnCreatAd_One}
                      handleChangeCityCreatAd_One={handleChangeCityCreatAd_One}
                      countryRef={countryRef}
                      cityRef={cityRef}
                      provinceRef={provinceRef}
                      validateCountry={validateCountry}
                      validateCity={validateCity}
                      validateProvince={validateProvince}
                      isLoading={isLoading}
                      isClearable={isClearable}
                      isSearchable={isSearchable}
                      filterConfig={filterConfig}
                      mystyle={mystyle}
                      t={t}
                    />
                  </>
                }
                right={
                  <>
                    <FormField
                      label='N° di ruoli ricercati'
                      error={errorCreatAd_One.n_roles_sought}
                    >
                      <input
                        type='number'
                        className={`form-control zip_input ${
                          errorCreatAd_One.n_roles_sought ? 'error' : ''
                        }`}
                        name='n_roles_sought'
                        value={CreatAd_One.n_roles_sought || ''}
                        ref={rolesSoughtRef}
                        onChange={handleOnCreatNumberOfRoles}
                        onBlur={validateNumberOfRoles}
                      />
                    </FormField>

                    <AddressControl
                      CreatAd_One={CreatAd_One}
                      setCreatAd_One={setCreatAd_One}
                      errorCreatAd_One={errorCreatAd_One}
                      setErrorCreatAd_One={setErrorCreatAd_One}
                      handleOnCreatAd_One={handleOnCreatAd_One}
                      handleOnGetAddress={handleOnGetAddress}
                      validateStreetNumber={validateStreetNumber}
                      validateZipCode={validateZipCode}
                      streetRef={streetRef}
                      zipCodeRef={zipCodeRef}
                      mystyle={mystyle}
                      t={t}
                    />
                  </>
                }
              />

              {renderReferentSection()}
              {renderContactSection()}

              <div className='seeking_form_btn'>
                <div className='btn-prev' />
                <NextButton nextStep={nextStep} />
              </div>
            </div>
          </form>
          </>
          )}
        </div>
      </div>
      <Footer />
    </>
  )
}

export default CreaAnnuncioOne
