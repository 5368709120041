import React, { useState, useEffect } from "react";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Footer from "../../../Components/Footer";
import Header from "../../../Components/Header";
import CheckoutForm from "./CheckoutForm";
import { useNavigate, useLocation } from "react-router-dom";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_CLIENT_ID);

function Stripe(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const [product, setProduct] = useState(props.product);
    const [orderId, setOrderId] = useState(props.order_id);

    useEffect(() => {
        const data = location?.state?.product;
        if (!data) {
            //navigate('/company/payment-system');
        } else {
            setProduct(data);
        }
    }, [location, navigate]);

    const appearance = {
        theme: 'stripe',
    };

    const options = {
        mode: 'payment',
        amount: product.price ? Math.round(product.price * 100) : 1000,
        currency: product.price_currency?.toLowerCase() || "eur",
        productName: product.package_name,    
        token_amount: product.token_amount,
        appearance: appearance,
        layout: {
            type: 'tabs',
            defaultCollapsed: false,
        },
        //clientSecret: props.sessionId
    };


    return (
        <>
            
            <Elements stripe={stripePromise} options={options}>
                <CheckoutForm options={options} order_id={orderId} />
            </Elements>
            
        </>
    );
}

export default Stripe;