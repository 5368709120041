import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import Breadcrumbs from "../Components/Breadcrumbs";
import Footer from "../Components/Footer";
import FormBuilder from "../Components/FormBuilder";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { requestCompanyRegister } from "../Redux/actions";
import { toast } from "react-toastify";

const FIELDS = [
  {
    label: "Qual'è il tipo di servizio da te offerto?",
    name: "service_type",
    type: "text",
    mandatory: true,
  },
  {
    label: "Qual'è il servizio da te offerto?",
    name: "service_name",
    type: "text",
    mandatory: true,
  },
  {
    label: "Descrivi i servizi da te offerti",
    name: "services_description",
    type: "text_area",
    mandatory: false,
  },
  {
    label: "Inserisci una tua foto oppure un logo",
    name: "propic",
    type: "file",
    mandatory: false,
  },
  {
    label: "Inserisci i tuoi link social o siti web",
    name: "website_url",
    type: "url",
    mandatory: false,
    multiple: true,
  },
];

const ProfessionalRequirements = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [searchParams] = useSearchParams();

  const handleSubmit = (formData) => {
    const data = {
      ...formData,
      ...location.state?.formData,
      flag_professional: searchParams.get("type") === "professional",
      flag_artisan: searchParams.get("type") === "artisan",
    };

    setLoading(true);
    props.requestCompanyRegister({
      data,
    });
  };

  useEffect(() => {
    const registrationListener = async () => {
      if (!props.company.comRegisterData) return;
      if (showModal) return;
      setLoading(false);
      if (props.company.comRegisterData.status === 200) {
        setShowModal("login");
      } else {
        toast.error(
          props.company.comRegisterData?.Message ??
            "Errore nel processo di registrazione",
          {
            theme: "colored",
            autoClose: 8000,
          }
        );
      }
    };
    registrationListener();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.company.comRegisterData]);

  return (
    <div className="min-vh-100 d-flex flex-column">
      <Header showModal={showModal} />
      <Breadcrumbs />
      <div className="container mt-4 min-vh-100">
        <div className="h-100">
          <div className="seekng_job_title mb-4">
            <h2>Alcune informazioni su di te</h2>
          </div>
          <FormBuilder
            fields={FIELDS}
            onSubmit={handleSubmit}
            submitLabel={loading ? "Carico..." : "Avanti"}
            onBack={() => navigate(-1)}
            submitDisabled={loading}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return { company: state.company, candidate: state.candidate };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestCompanyRegister,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfessionalRequirements);
