import LeftArrow from "../images/left_orange_arrow.png";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";

export const InputFieldRow = ({
  name,
  type,
  mandatory,
  value,
  multiple,
  variant,
  onRemove,
  step,
  onChange,
  onAdd,
  errored,
}) => {
  return (
    <div className={`d-flex gap-2 ${!errored ? "text-danger" : ""}`}>
      <input
        type={type}
        name={name}
        value={value}
        onChange={(e) => {
          onChange(e);
        }}
        className="form-control"
        {...(step ? { step } : {})}
      />
      {multiple ? (
        <button
          onClick={variant === "danger" ? onRemove : onAdd}
          className={`btn btn-primary ${
            variant === "danger" ? "btn-danger" : ""
          }`}
        >
          {variant === "danger" ? "Rimuovi" : "Aggiungi"}
        </button>
      ) : (
        <></>
      )}
    </div>
  );
};

const FormBuilder = ({
  fields,
  onSubmit,
  submitLabel = "Avanti",
  values,
  selectOptions,
  onChange,
  onBack,
  submitDisabled,
}) => {
  const [errored, setErrored] = useState([]);
  const [_fields, setFields] = useState(fields);
  const [multipleFieldInputValue, setMultipleFieldInputValue] = useState({});
  const [formData, setFormData] = useState(
    values ??
      Object.fromEntries(
        fields.map((field) => [field.name, field.multiple ? [] : ""])
      )
  );

  useEffect(() => {
    setFields(fields);
    const blocksFields = fields.filter((f) => f.type === "blocks");
    if (blocksFields.length) {
      setFormData((prev) => {
        const updates = {};
        blocksFields.forEach((field) => {
          if (!prev[field.name]?.length) {
            updates[field.name] = [
              Object.fromEntries(field.fields.map((f) => [f.name, ""])),
            ];
          }
        });
        return { ...prev, ...updates };
      });
    }
  }, [fields]);

  useEffect(() => {
    if (onChange) onChange(formData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const shouldHideField = (field) => {
    if (!field.display_dependencies) return false;

    return !field.display_dependencies.some((dependency) => {
      const dependentValues = formData[dependency.field] || [];
      return dependentValues.some((value) => dependency.values.includes(value));
    });
  };

  const handleSubmit = () => {
    let erroredFields = [];
    for (const field of fields) {
      if (field.mandatory && !formData[field.name]?.length) {
        erroredFields.push(field.name);
      }
    }
    setErrored(erroredFields);
    if (erroredFields.length) return;
    onSubmit(formData);
  };

  const handleAddBlock = (blockData, blockKey) => {
    if (!blockKey) return;
    setFormData((p) => ({
      ...p,
      [blockKey]: [...(p[blockKey] ?? []), blockData],
    }));
  };

  const handleRemoveBlock = (blockKey) => {
    setFormData((p) => ({
      ...p,
      [blockKey]: p[blockKey].slice(0, -1),
    }));
  };

  return (
    <div>
      <div className="d-flex flex-column gap-4">
        {_fields.map((field, i) => {
          if (shouldHideField(field)) {
            return null;
          }

          return (
            <div key={i}>
              {(() => {
                switch (field.type) {
                  case "blocks":
                    return (
                      <div key={i} className="mt-4">
                        {Array.from({
                          length: formData[field.name]?.length || 1,
                        }).map((_, i) => (
                          <FormBuilder
                            key={i}
                            fields={field.fields}
                            selectOptions={selectOptions}
                            onChange={(data) => {
                              setFormData((p) => ({
                                ...p,
                                [field.name]: (p[field.name] || []).map(
                                  (_, index) =>
                                    index === i ? data : p[field.name][index]
                                ),
                              }));
                            }}
                            submitLabel={
                              i === formData[field.name]?.length - 1
                                ? field.submitLabel
                                : "Rimuovi"
                            }
                            onSubmit={(data) => {
                              if (i === formData[field.name]?.length - 1) {
                                handleAddBlock(data, field.name);
                              } else {
                                handleRemoveBlock(field.name);
                              }
                            }}
                          />
                        ))}
                      </div>
                    );
                  case "phone":
                    return (
                      <div className="row gx-4 mb-4">
                        <div className="col-md-12">
                          <label>{field.label}</label>
                          <PhoneInput
                            className={`form-control set_max_width numero_cellulare mt-2 ${
                              errored.includes(field.name)
                                ? "border-danger"
                                : ""
                            }`}
                            country={"it"}
                            value={formData[field.name] || ""}
                            placeholder={field.label}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                [field.name]: e,
                              });
                            }}
                            enableLongNumbers={18}
                          />
                        </div>
                      </div>
                    );
                  case "select":
                    return (
                      <div
                        className={`row gx-4 ${
                          errored.includes(field.name) ? "text-danger" : ""
                        }`}
                      >
                        <div className="col-md-12">
                          <label>{field.label}</label>
                          <Select
                            isMulti={field.multiple}
                            name={field.name}
                            className={`form-control set_max_width select_dropdown mt-2 ${
                              errored.includes(field.name)
                                ? "border-danger bg-danger"
                                : ""
                            }`}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: errored.includes(field.name)
                                  ? "#dc3545"
                                  : baseStyles.borderColor,
                                boxShadow: errored.includes(field.name)
                                  ? "0 0 0 1px #dc3545"
                                  : state.isFocused
                                  ? "0 0 0 1px #ced4da"
                                  : "none",
                                "&:hover": {
                                  borderColor: errored.includes(field.name)
                                    ? "#dc3545"
                                    : baseStyles.borderColor,
                                },
                              }),
                            }}
                            options={selectOptions[field.name]}
                            value={
                              formData[field.name]
                                ? formData[field.name].map((value) => ({
                                    label: selectOptions[field.name].find(
                                      (option) => option.value === value
                                    )?.label,
                                    value: value,
                                  }))
                                : undefined
                            }
                            onChange={(e) => {
                              if (field.multiple) {
                                setFormData((p) => ({
                                  ...p,
                                  [field.name]: [...p[field.name], e?.value],
                                }));
                              } else {
                                setFormData({
                                  ...formData,
                                  [field.name]: e?.value
                                    ? [e?.value]
                                    : undefined,
                                });
                              }
                            }}
                            placeholder={
                              "Seleziona un'opzione" // per " + field.label
                            }
                            isClearable={true}
                          />
                        </div>
                      </div>
                    );
                  case "text":
                  case "number":
                  case "url":
                  case "email":
                    return (
                      <div className="d-flex flex-column gap-2">
                        <label>{field.label}</label>
                        {field.multiple &&
                          formData[field.name]?.map((value, i) => (
                            <InputFieldRow
                              key={i}
                              name={field.name}
                              type={field.type}
                              value={value}
                              step={field.step}
                              multiple={field.multiple}
                              variant="danger"
                              onRemove={() => {
                                setFormData((p) => ({
                                  ...p,
                                  [field.name]: p[field.name].filter(
                                    (_, index) => index !== i
                                  ),
                                }));
                              }}
                            />
                          ))}
                        <InputFieldRow
                          name={field.name}
                          type={field.type}
                          value={
                            field.multiple
                              ? multipleFieldInputValue[i]
                              : formData[field.name]
                          }
                          step={field.step}
                          multiple={field.multiple}
                          onAdd={() => {
                            setFormData((p) => ({
                              ...p,
                              [field.name]: [
                                ...p[field.name],
                                multipleFieldInputValue[i],
                              ],
                            }));
                            setMultipleFieldInputValue((p) => ({
                              ...p,
                              [i]: "",
                            }));
                          }}
                          onChange={(e) => {
                            if (!field.multiple) {
                              setFormData({
                                ...formData,
                                [field.name]: e.target.value,
                              });
                            } else {
                              setMultipleFieldInputValue((p) => ({
                                ...p,
                                [i]: e.target.value,
                              }));
                            }
                          }}
                        />
                      </div>
                    );
                  case "text_area":
                    return (
                      <div
                        className={`${
                          errored.includes(field.name) ? "text-danger" : ""
                        }`}
                      >
                        <label>{field.label}</label>
                        <textarea
                          name={field.name}
                          value={formData[field.name]}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [field.name]: e.target.value,
                            })
                          }
                          className={`form-control ${
                            errored.includes(field.name) ? "border-danger" : ""
                          }`}
                        />
                      </div>
                    );
                  case "file":
                    return (
                      <div
                        className={`${
                          errored.includes(field.name) ? "text-danger" : ""
                        }`}
                      >
                        <label>{field.label}</label>
                        <input
                          type="file"
                          name={field.name}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [field.name]: e.target.files[0],
                            })
                          }
                          className={`form-control ${
                            errored.includes(field.name) ? "border-danger" : ""
                          }`}
                        />
                      </div>
                    );
                  default:
                    return <div></div>;
                }
              })()}
            </div>
          );
        })}
      </div>
      <div className="mt-auto">
        <div
          className="seeking_form_btn d-flex mt-4 gap-3"
          style={{ marginLeft: "auto", width: "fit-content" }}
        >
          {onBack ? (
            <div className="back_btn">
              <button
                className="btn btn_default btn_orange_outline"
                type="button"
                onClick={onBack}
              >
                <img
                  src={LeftArrow}
                  style={{ width: "16px", marginRight: "8px" }}
                  alt="Main Logo"
                />
                Indietro
              </button>
            </div>
          ) : (
            <></>
          )}
          <div
            onClick={handleSubmit}
            style={{ marginLeft: "auto" }}
            className={`btn btn_default btn_orange_outline ${
              submitDisabled
                ? "disabled cursor-not-allowed pointer-events-none"
                : ""
            }`}
          >
            {submitLabel}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormBuilder;
