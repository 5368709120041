import React from 'react';
import CreatableSelect from 'react-select/creatable';

function SectorLevel(props) {
    const {
        i,
        x,
        validateExperienceSector,
        selectedOptionsExperience,
        focusRefs,
        errorexperience,

        //============sub memu===================

        handleSubMenuExpChange,
        mystyle,
        t,
        handleChangeSectorLevel,
        sectorsLevel,
        subSubSectorsLevel
    } = props

    const isClearable = true;

    const selectedSubmenuOption = subSubSectorsLevel.filter(
        option => option.sectorName === selectedOptionsExperience[i]?.submenu
    );

    const hasSubSubmenuOptions = selectedSubmenuOption && selectedSubmenuOption?.length > 0;

    return (
        <>
            <div className="col-lg-6 col-md-6" style={{ padding: "5px" }}>
                <div className="form-group">
                    <label htmlFor="sector">{t('exprience.sector')}</label>
                    <CreatableSelect
                        className={`form-control select_dropdown ${errorexperience[i].sector ? 'error' : ''}`}
                        options={sectorsLevel}
                        defaultValue={x.sector && x.sector ? { label: `${x.sector}` } : ""}
                        onChange={(selectedOption) => handleChangeSectorLevel(selectedOption, i)}
                        ref={(el) => {
                            focusRefs.experience.current[i] = focusRefs.experience.current[i] || {};
                            focusRefs.experience.current[i].sector = el;
                        }}
                        placeholder="Seleziona o inserisci il tuo Settore"
                        onBlur={() => validateExperienceSector(i)}
                        isClearable={isClearable}
                    />
                    {errorexperience[i].sector && (
                        <div style={mystyle}>
                            {errorexperience[i].sector}
                        </div>
                    )}
                </div>
            </div>

            {selectedOptionsExperience[i]?.submenu && hasSubSubmenuOptions &&
                (
                    <>
                        <div className="col-lg-6 col-md-6" style={{ padding: "5px" }}>
                            <div className="form-group">
                                <label htmlFor="sector">Sottosettore</label>
                                <select
                                    className='form-control'
                                    // className={`form-control ${errorexperience[i]?.sector ? 'error' : ''}`}
                                    name="sector"
                                    id={"sector" + i}
                                    onChange={(e) => handleSubMenuExpChange(e, i)}

                                    value={selectedOptionsExperience[i]?.subSubmenu || ""}
                                // ref={(el) => {
                                //     focusRefs.experience.current[i] = focusRefs.experience.current[i] || {};
                                //     focusRefs.experience.current[i].sector = el;
                                // }}
                                >
                                    <option value="">Settore</option>
                                    {selectedSubmenuOption?.map((subOption, index) => (
                                        <option key={index} value={subOption.subSector}>
                                            {subOption.subSector}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </>
                )}
        </>
    )
}

export default SectorLevel