// File: /src/Franchisor/search-franchisor/SearchResults.js
// Created Date: Friday September 20th 2024
// Author: Christian Nonis <alch.infoemail@gmail.com>
// -----
// Last Modified: Friday September 20th 2024 11:26:34 am
// Modified By: the developer formerly known as Christian Nonis at <alch.infoemail@gmail.com>
// -----

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Footer from "../../Components/Footer";
import CandidateJobsDetails from "../../Company/jobs/Candidate-jobs-details";
import Header from "../../Components/Header";
import { useAppNavigation } from "../../utils/routing";
import CompanyLogo from "../../images/logo.png";
import { resetGetJobList } from "../../Redux/actions";

const SearchResults = (props) => {
  const [jobs, setJobs] = useState([]);
  const [jobId, setJobId] = useState(null);
  const [showModal, setShowModal] = useState("");
  const { location, navigateTo } = useAppNavigation();

  const user = props.franchisor?.loginData?.data?.Message?.id;

  useEffect(() => {
    const data = location.state?.jobListData || [];
    setJobs(data);
  }, [location.state?.jobListData]);

  const getValue = (value) => (user ? value : "xxxxxxxxxxxxxxxx");

  const redirectToJobFinder = () => {
    props.resetGetJobList();
    navigateTo("/franchisor/search2", { state: { jobListData: [] } });
  };

  const redirectToJobDetails = (id) => {
    let destination = location.pathname;
    const newOptions = { state: { jobListData: jobs } };
    if (!props.franchisor.loginData) {
      setShowModal("login");
      newOptions.params = {
        userType: "franchisor",
      };
    }
    if (!props.franchisor.loginData?.data.Message.flag_franchisee) {
      destination = "/franchisor/search";
    }
    setJobId(id);
    navigateTo(destination, newOptions);
  };

  return (
    <>
      <Header showModal={showModal} setShowModal={setShowModal} />
      <div className="container">
        <div className="new_research_btn mt-4 text-end">
          <button
            className="btn btn_default btn_orange_outline"
            type="button"
            onClick={redirectToJobFinder}
          >
            Nuova ricerca
          </button>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-6 col-12">
            {jobs.length > 0 && (
              <div className="job_listing_sec">
                {/* <div className="container"> */}

                <div className="job_listing_blocks">
                  {jobs.map((job) => (
                    <div
                      key={job.id}
                      className={`job_listing_block ${
                        !user ? "blur_effect" : ""
                      }`}
                      onClick={() => redirectToJobDetails(job.id)}
                    >
                      <a className="redirect_a">
                        <div className="job_listing_block_inner">
                          <div className="job_title">
                            <h2>{getValue(job.e_mail || "")}</h2>
                          </div>
                          <div className="logo_detailbtn">
                            <div className="company_logo">
                              <img src={CompanyLogo} alt="company logo" />
                            </div>
                            <div className="detail_btn">
                              <button
                                className="btn btn_default btn_green_outline"
                                type="button"
                                // onClick={() => redirectToJobDetails(job.id)}
                              >
                                Salva
                              </button>
                            </div>
                          </div>
                          {/* <div className="job_description">
                                              <p>{getValue(job.job_description)}</p>
                                          </div> */}
                          <div className="sector_name">
                            <p>
                              <strong>Name:&nbsp;&nbsp;</strong>
                              {getValue(job?.title?.title || "")}{" "}
                              {getValue(job.name || "")}{" "}
                              {getValue(job.surname || "")}
                            </p>
                          </div>
                          <div className="sector_name">
                            <p>
                              <strong>cellphone:&nbsp;&nbsp;</strong>
                              {getValue(job?.cellphone || "")}
                            </p>
                          </div>
                          <div className="job_salary_address">
                            {/* <div className="job_salary">
                                                  <p>
                                                      <strong>Retribuzione:&nbsp;&nbsp;</strong>
                                                      {getValue(
                                                          job?.salary?.fixedAmount
                                                              ? `${job?.salary?.fixedAmount} ${job?.salary?.reccurency}`
                                                              : job?.salary?.minAmount && job?.salary?.maxAmount
                                                                  ? `${job?.salary?.minAmount} - ${job?.salary?.maxAmount} ${job?.salary?.reccurency}`
                                                                  : ""
                                                      )}
                                                  </p>
                                              </div> */}
                            <div className="job_address">
                              <p>
                                <strong>Luogo:&nbsp;&nbsp;</strong>
                                <span>
                                  {job.address ? (
                                    getValue(
                                      job.address[0]
                                        ? `${
                                            job.address[0]?.city
                                              ? job.address[0]?.city
                                              : ""
                                          }${
                                            job.address[0]?.province
                                              ? `, ${job.address[0]?.province}`
                                              : ""
                                          }${
                                            job.address[0]?.country
                                              ? `, ${job.address[0]?.country}`
                                              : ""
                                          }`
                                        : ""
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="required_education_skills">
                            <div className="required_education">
                              <p>
                                {" "}
                                {job.educationalLevel?.levelType ? (
                                  <strong>
                                    Istruzione richiesta::&nbsp;&nbsp;
                                  </strong>
                                ) : (
                                  ""
                                )}{" "}
                                {getValue(
                                  job.educationalLevel?.levelType || ""
                                )}
                              </p>
                            </div>
                            {/* <div className="required_skills">
  
                                                  {user && job.skills && job.skills.length > 0 ?
                                                      (<>
                                                          <p>
                                                              <strong>
                                                                  Competenze richieste:&nbsp;&nbsp;
                                                              </strong>
  
                                                              {
                                                                  job.skills.map((skill, index) => (
                                                                      <span key={index}>{skill.skillName},</span>
                                                                  ))
                                                              }
  
                                                          </p>
                                                      </>
                                                      ) : (
                                                          ""
                                                      )}
                                              </div> */}
                          </div>
                        </div>
                      </a>
                    </div>
                  ))}
                </div>
                {/* </div> */}
              </div>
            )}
          </div>
          {jobId && user && (
            <div className="col-md-6 col-12">
              <CandidateJobsDetails id={jobId} />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  return { franchisor: state.franchisor };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ resetGetJobList }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
