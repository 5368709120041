// File: /src/Agent/opportunities-list/OpportunitiesListAgent.js
// Created Date: Monday September 16th 2024
// Author: Christian Nonis <alch.infoemail@gmail.com>
// -----
// Last Modified: Monday September 16th 2024 10:55:48 am
// Modified By: the developer formerly known as Christian Nonis at <alch.infoemail@gmail.com>
// -----

import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import CompanyLogo from "../../../src/images/logo.png";
import Footer from "../../Components/Footer";
import { useAppNavigation } from "../../utils/routing";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { receiveLogin, resetGetJobList } from "../../Redux/actions";
import CandidateJobsDetails from "../../Company/jobs/Candidate-jobs-details";
import Swal from "sweetalert2";

const LISTA_MOCKKATA = Array(3)
  .fill()
  .map((_, index) => ({
    id: Math.random() * Math.random(),
    e_mail: `test${index + 1}@test.com`,
    name: `John${index + 1}`,
    surname: `Doe${index + 1}`,
    cellphone: `123456789${index}`,
    title: {
      title: "Software Engineer",
    },
    address: [
      {
        city: "Rome",
        province: "RM",
        country: "Italy",
      },
    ],
    educationalLevel: {
      levelType: "Bachelor",
    },
  }));

const OpportunitiesListAgent = (props) => {
  const [jobs, setJobs] = useState([]);
  const [jobId, setJobId] = useState(null);
  const [showModal, setShowModal] = useState("");
  const { location, navigateTo } = useAppNavigation();

  const user = props.agent.loginData?.data?.Message?.flag_agent;

  useEffect(() => {
    const data = location.state?.jobListData || [];
    setJobs(data);

    return () => {
      if (props.candidate && "GetJobListData" in props.candidate) {
        props.candidate.GetJobListData = undefined;
        console.log("Setting candidate GetJobListData to undefined");
      }
      if (props.company && "GetJobListData" in props.company) {
        props.company.GetJobListData = undefined;
        console.log("Setting company GetJobListData to undefined");
      }
      if (props.agent && "GetJobListData" in props.agent) {
        props.agent.GetJobListData = undefined;
        console.log("Setting agent GetJobListData to undefined");
      }
      if (props.franchisor && "GetJobListData" in props.franchisor) {
        props.franchisor.GetJobListData = undefined;
      }
      props.resetGetJobList();
    };
  }, [
    location.state?.jobListData,
    props.candidate?.GetJobListData,
    props.company?.GetJobListData,
    props.agent?.GetJobListData,
    props.franchisor?.GetJobListData,
  ]);

  const getValue = (value) => (user ? value : "xxxxxxxxxxxxxxxx");

  const redirectToJobFinder = () => {
    props.resetGetJobList();
    navigateTo("/agent/opportunity-finder", { state: { jobListData: [] } });
  };

  const redirectToJobDetails = (id) => {
    let destination = location.pathname;
    const newOptions = { state: { jobListData: jobs } };

    if(localStorage.getItem('userType') === 'company') {
      Swal.fire({
        title: 'Vuoi guardare gli annunci di lavoro?',
        text: 'Registrati come agente utilizzando un nuovo account',

      })
      return;
    }    

    if (!props.agent.loginData && !user) {
      setShowModal("login");
      newOptions.params = {
        userType: "agent",
      };
    } else if (!props.agent.loginData?.data.Message.flag_agent) {
      destination = "/agent/register";
    }
    setJobId(id);
    navigateTo(destination, newOptions);
  };

  return (
    <>
      <Header showModal={showModal} setShowModal={setShowModal} />
      <div className="container">
        <div className="new_research_btn mt-4 text-end">
          <button
            className="btn btn_default btn_orange_outline"
            type="button"
            onClick={redirectToJobFinder}
          >
            Nuova ricerca
          </button>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-6 col-12">
            {jobs.length > 0 && (
              <div className="job_listing_sec">
                {/* <div className="container"> */}

                <div className="job_listing_blocks">
                  {jobs.map((job) => (
                    <div
                      key={job.id}
                      className={`job_listing_block ${
                        !user ? "blur_effect" : ""
                      }`}
                      onClick={() => redirectToJobDetails(job.id)}
                    >
                      <a className="redirect_a">
                        <div className="job_listing_block_inner">
                          <div className="job_title">
                            <h2>
                              {job.job_title}
                            </h2>
                          </div>
                          <div className="logo_detailbtn">
                            <div className="company_logo">
                              <img src={CompanyLogo} alt="company logo" />
                            </div>
                            <div className="detail_btn">
                              <button
                                className="btn btn_default btn_green_outline"
                                type="button"
                                // onClick={() => redirectToJobDetails(job.id)}
                              >
                                Dettagli di lavoro
                              </button>
                            </div>
                          </div>
                          {/* <div className="job_description">
                                            <p>{getValue(job.job_description)}</p>
                                        </div> */}
                          <div className="sector_name">
                            <p>
                              <strong>Settore:&nbsp;&nbsp;</strong>
                              {job.sector?.sectorName}
                            </p>
                          </div>
                          <div className="sector_name">
                            <p>
                              <strong>cellphone:&nbsp;&nbsp;</strong>
                              {getValue(job?.cellphone || "")}
                            </p>
                          </div>
                          <div className="job_salary_address">
                            {/* <div className="job_salary">
                                                <p>
                                                    <strong>Retribuzione:&nbsp;&nbsp;</strong>
                                                    {getValue(
                                                        job?.salary?.fixedAmount
                                                            ? `${job?.salary?.fixedAmount} ${job?.salary?.reccurency}`
                                                            : job?.salary?.minAmount && job?.salary?.maxAmount
                                                                ? `${job?.salary?.minAmount} - ${job?.salary?.maxAmount} ${job?.salary?.reccurency}`
                                                                : ""
                                                    )}
                                                </p>
                                            </div> */}
                            <div className="job_address">
                              <p>
                                <strong>Luogo:&nbsp;&nbsp;</strong>
                                <span>
                                  {job.address ? (
                                    getValue(
                                      job.address
                                        ? `${
                                            job.address?.city
                                              ? job.address?.city
                                              : ""
                                          }${
                                            job.address?.province
                                              ? `, ${job.address?.province}`
                                              : ""
                                          }${
                                            job.address?.country
                                              ? `, ${job.address?.country}`
                                              : ""
                                          }`
                                        : ""
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="required_education_skills">
                            <div className="required_education">
                              <p>
                                {" "}
                                {job.educationalLevel?.levelType ? (
                                  <strong>
                                    Istruzione richiesta::&nbsp;&nbsp;
                                  </strong>
                                ) : (
                                  ""
                                )}{" "}
                                {getValue(
                                  job.educationalLevel?.levelType || ""
                                )}
                              </p>
                            </div>
                            {/* <div className="required_skills">

                                                {user && job.skills && job.skills.length > 0 ?
                                                    (<>
                                                        <p>
                                                            <strong>
                                                                Competenze richieste:&nbsp;&nbsp;
                                                            </strong>

                                                            {
                                                                job.skills.map((skill, index) => (
                                                                    <span key={index}>{skill.skillName},</span>
                                                                ))
                                                            }

                                                        </p>
                                                    </>
                                                    ) : (
                                                        ""
                                                    )}
                                            </div> */}
                          </div>
                        </div>
                      </a>
                    </div>
                  ))}
                </div>
                {/* </div> */}
              </div>
            )}
          </div>
          {jobId && user && (
            <div className="col-md-6 col-12">
              <CandidateJobsDetails id={jobId} user={{ company: false }} />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  return { agent: state.agent };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetGetJobList,
      receiveLogin,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OpportunitiesListAgent);
