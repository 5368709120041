// File: /src/Ads/ReceivedQuotations.js
// Created Date: Thursday January 9th 2025
// Author: Christian Nonis <alch.infoemail@gmail.com>
// -----
// Last Modified: Thursday January 9th 2025 4:33:33 pm
// Modified By: the developer formerly known as Christian Nonis at <alch.infoemail@gmail.com>
// -----

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import AdsBuilder from "../Components/AdsBuilder";
import axios from "axios";
import Swal from "sweetalert2";

const CARD_FIELDS = {
  title1: ["by_name", "by_surname"],
  title2: [""],
  subtitle: ["text"],
  date: ["date"],
  highlighted_elements: [
    {
      label: "Prezzo",
      key: "price",
    },
  ],
  tags: ["email", "telefono", "accepted"],
};

const DETAILED_FIELDS = {
  title1: ["by_name", "by_surname"],
  image: [],
  subtitle: [],
  description: ["text"],
  highlights: ["email", "telefono", "accepted"],
  blocks: [],
  highlighted_elements: [],
  info: ["price"],
};

const ReceivedQuotations = (props) => {
  const [quotations, setQuotations] = useState([]);
  const [showModal, setShowModal] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirmPreventivo = async (ad) => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_HOST}wideer/q/${ad.id}/`,
        {
          accepted: true,
        },
        {
          headers: {
            Authorization: `Bearer ${props.candidate?.loginData?.data?.Message.access}`,
          },
        }
      );
      Swal.fire({
        title: "Preventivo Accettato",
        text: "Il preventivo è stato accettato con successo",
        icon: "success",
        confirmButtonText: "OK",
      });
      await getQuotations();
    } catch (err) {
      console.log(err);
      Swal.fire({
        title: "Errore",
        text: "Si è verificato un errore",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const getQuotations = async () => {
    try {
      setIsLoading(true);
      const id = props.candidate?.loginData?.data?.Message?.id;
      if (!id) return;
      const response = await axios.get(
        `${process.env.REACT_APP_API_HOST}auth/register/?id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${props.candidate?.loginData?.data?.Message.access}`,
          },
        }
      );
      const serializedData = (
        response.data.Message?.[0]?.quotations_o ?? []
      ).map((quotation) => ({
        accepted: quotation.accepted,
        id: quotation.id,
        price: quotation.price,
        text: quotation.text,
        by_name: quotation.offer.created_by.name,
        by_surname: quotation.offer.created_by.surname,
        telefono: quotation.offer.created_by.cellphone,
        by_id: quotation.offer.created_by.id,
        email: quotation.offer.created_by.email,
        date: quotation.offer.created_at,
      }));
      setQuotations(serializedData);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getQuotations();
  }, [props.candidate?.loginData?.data?.Message.id]);

  return (
    <div>
      <Header showModal={showModal} setShowModal={setShowModal} />
      <div className="container min-vh-100">
        <div className="row justify-content-center">
          <div className="col-md-6 col-12">
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center mt-5">
                <div
                  className="spinner-border text-danger"
                  role="status"
                  style={{
                    width: "4rem",
                    height: "4rem",
                    borderWidth: "0.4rem",
                  }}
                ></div>
              </div>
            ) : quotations.length ? (
              <AdsBuilder
                cardFields={CARD_FIELDS}
                detailedFields={DETAILED_FIELDS}
                blockFields={{
                  title: "question",
                  content: "answer",
                }}
                ads={quotations}
                onAction={handleConfirmPreventivo}
                actionLabel="Accetta Preventivo"
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return { company: state.company, candidate: state.candidate };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ReceivedQuotations);
