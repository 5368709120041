// File: /src/QuestionsTree/QuestionsTree.js
// Created Date: Wednesday January 8th 2025
// Author: Christian Nonis <alch.infoemail@gmail.com>
// -----
// Last Modified: Wednesday January 8th 2025 9:53:43 am
// Modified By: the developer formerly known as Christian Nonis at <alch.infoemail@gmail.com>
// -----

import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import Breadcrumbs from "../Components/Breadcrumbs";
import FormBuilder from "../Components/FormBuilder";
import Footer from "../Components/Footer";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";

const QuestionsTree = (props) => {
  const [fields, setFields] = useState([]);
  const [options, setOptions] = useState({});

  const [searchParams] = useSearchParams();
  const serviceId = searchParams.get("service");
  const macroServiceId = searchParams.get("macro_service");

  const navigate = useNavigate();

  const handleSubmit = async (formData) => {
    try {
      if (!serviceId) return toast.error("Servizio non trovato");
      if (!macroServiceId) return toast.error("Servizio non trovato");
      if (!props.candidate?.loginData?.data.Message.id)
        return toast.error("Utente non trovato");

      const description = formData.description;
      delete formData.description;
      const idPiuCorto =
        Math.random().toString(36).substring(2, 15) + Date.now();

      const payload = {
        created_by: Number(props.candidate?.loginData?.data.Message.id),
        macro_service: Number(macroServiceId),
        description,
        service: Number(serviceId),
        answers: Object.values(formData).flat(),
        url_id: idPiuCorto,
        flag_professional: searchParams.get("rtype") === "professional",
        flag_artisan: searchParams.get("rtype") === "artisan",
        url: `${process.env.REACT_APP_URL}home/opportunities/?id=${idPiuCorto}&serviceId=${serviceId}`,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}wideer/o/`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${props.candidate?.loginData?.data.Message.access}`,
          },
        }
      );
      if (response.data) {
        toast.success("Richiesta inviata con successo", {
          theme: "colored",
          autoClose: 8000,
        });
        navigate(-1);
      }
    } catch (error) {
      console.error(error);
      if (Number(error.response.data.ErrorCode) === 560) {
        toast.error("Servizio non disponibile, ci scusiamo per il disagio.", {
          theme: "colored",
          autoClose: 8000,
        });
      }
    }
  };

  useEffect(() => {
    const getTree = async () => {
      try {
        if (!serviceId) return;
        const response = await axios.get(
          `${process.env.REACT_APP_API_HOST}wideer/getq/${serviceId}/`,
          {
            headers: {
              Authorization: `Bearer ${props.candidate?.loginData?.data.Message.access}`,
            },
          }
        );
        if (response?.data) {
          setFields(
            response.data
              .filter((q) => q.answers.length)
              .map((q) => ({
                label: q.question,
                type: "select",
                name: q.id,
                mandatory: true,
              }))
          );
          setOptions(
            Object.fromEntries(
              response.data.map((q) => [
                q.id,
                q.answers.map((a) => ({ value: a.id, label: a.answer })),
              ])
            )
          );
        }
      } catch (error) {
        console.error(error);
      }
    };
    getTree();
  }, []);

  return (
    <div className="min-vh-100 d-flex flex-column">
      <Header />
      <Breadcrumbs />
      <div className="container mt-4 min-vh-100">
        <div className="h-100">
          <div className="seekng_job_title mb-4">
            <h2>Richiedi servizio</h2>
          </div>
          <FormBuilder
            fields={[
              ...fields,
              {
                label: "Descrizione",
                type: "text_area",
                name: "description",
                mandatory: true,
              },
            ]}
            onSubmit={handleSubmit}
            submitLabel="Richiedi"
            selectOptions={options}
            values={{}}
            onBack={() => navigate(-1)}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return { candidate: state.candidate, company: state.company };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsTree);
