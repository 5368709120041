import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import { requestGetJobList, requestTwelveTokenSpent } from "../../Redux/actions";
import CompanyLogo from "../../../src/images/logo.png";
import { useTranslation } from "react-i18next";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { jwtDecode } from "jwt-decode";
import CandidateJobsDetails from "./Candidate-jobs-details";
import { useAppNavigation } from "../../utils/routing";
import { resetGetJobList } from "../../Redux/actions";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import axios from 'axios';

function CandidateJobsList(props) {
  const [jobs, setJobs] = useState([]);
  const [JobId, setJobId] = useState(null);
  const [details, setDetails] = useState(null);
  const [user, setUser] = useState(null);
  const [showModal, setShowModal] = useState("");
  const [canSeeResumes, setCanSeeResumes] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const { navigateTo } = useAppNavigation();
  console.log(props, "questo è props");

  useEffect(() => {
    const data = location.state?.jobListData || [];
    setJobs(data);

    return () => {
      if (props.candidate && "GetJobListData" in props.candidate) {
        props.candidate.GetJobListData = undefined;
        console.log("Setting candidate GetJobListData to undefined");
      }
      if (props.company && "GetJobListData" in props.company) {
        props.company.GetJobListData = undefined;
        console.log("Setting company GetJobListData to undefined");
      }
      if (props.agent && "GetJobListData" in props.agent) {
        props.agent.GetJobListData = undefined;
        console.log("Setting agent GetJobListData to undefined");
      }
      if (props.franchisor && "GetJobListData" in props.franchisor) {
        props.franchisor.GetJobListData = undefined;
      }
      props.resetGetJobList();
    };
  }, [
    location.state?.jobListData,
    props.candidate?.GetJobListData,
    props.company?.GetJobListData,
    props.agent?.GetJobListData,
    props.franchisor?.GetJobListData,
  ]);

  useEffect(() => {
    const companyData = props?.company?.getCompanyData;
    const employeeData = props?.candidate?.GetCandidateData;
    const agentData = props?.agent?.CandidateProfileData;

    if (props.company?.loginData?.data.Message.flag_company) {
      setUser({ ...companyData, ...props.candidate?.loginData?.data.Message });
    }

    if (props.candidate?.loginData?.data.Message.flag_employee) {
      setUser({ ...employeeData, ...props.candidate?.loginData?.data.Message });
    }

    if (props.candidate?.loginData?.data.Message.flag_agent) {
      setUser({ ...agentData, ...props.candidate?.loginData?.data.Message });
    }
    if (props.candidate?.loginData?.data.Message.can_see_resumes) {
      setCanSeeResumes(true);
    }
  }, [props.company, props.candidate, props.agent]);

  useEffect(() => {
    if (location.state?.resultsType === "employees") {
      setUser((p) => ({ ...p, company: true }));
    }

    if (location.state?.resultsType === "jobs") {
      setUser((p) => ({ ...p, company: false }));
    }

    if (location.state?.resultsType === "agents") {
      setUser((p) => ({ ...p, company: true }));
    }
  }, [location.state]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp > currentTime) {
          setUser((p) => ({ ...p, ...decodedToken }));
        } else {
          console.warn("Token has expired");
        }
      } catch (error) {
        console.error("Invalid token", error);
      }
    }
    const can_see_resumes = (localStorage.getItem('can_see_resumes') === 'true');
    if(can_see_resumes) {
      setCanSeeResumes(true);
    }
  }, []);

  const getValue = (value) => (user.user_id ? value : "xxxxxxxxxxxxxxxx");
  console.log(user, "user");
  const redirectToJobDetails = (id, details) => {
    const token = localStorage.getItem('token');
    if (!user?.flag_company && !user?.user_id) {
      navigateTo(location.pathname, {
        state: location.state,
        params: { userType: "candidate" },
      });
      setShowModal("login");
      return;
    }
    if(!props.candidate.loginData?.data.Message.flag_employee && !props.candidate?.loginData?.data.Message.flag_company) {
      navigateTo('/company/register');
      return;
    }
    if (user?.flag_company && !props.candidate?.loginData?.data.Message.flag_company) {
      navigateTo(location.pathname, {
        state: location.state,
        params: { userType: "company" },
      });
      setShowModal("login");
      return;
    }
    if(user?.flag_employee) {
      navigateTo('/company/register/');
      return;
    }
    if (props.candidate.loginData?.data.Message.flag_company && !canSeeResumes) {
      Swal.fire({
        title: 'Vuoi usare 12 crediti per vedere i Curricula?',
        text: 'Potrai vedere i cv per 30 giorni a partire da oggi',
        confirmButtonText: 'Si',
        showCancelButton: true,
        cancelButtonText: 'No',
        reverseButtons: true,
      }).then(result => {
        console.log(result, "result twelve")
        if(result.isConfirmed) {
          /*props.requestTwelveTokenSpent({
            token: token
          });*/
          const token = localStorage.getItem('token');
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          };
        
          
          axios
            .post(
              `${process.env.REACT_APP_API_HOST}wideer/a/30/`,
              {},
              {
                headers: headers,
              }
            )
            .then((response) => {
              console.log(response, 'twelveresponse');
              if(response.data.Message === 'insufficient tokens') {
                setCanSeeResumes(false);
                Swal.fire({
                  title: 'Non hai crediti sufficienti.',
                  text: 'Acquista altri crediti per visualizzare i CV.',
                  confirmButtonText: 'Ok',
                  showCancelButton: true,
                  cancelButtonText: 'No',
                  reverseButtons: true,
                }).then(result => {
                  if(result.isConfirmed) {
                    navigate("/company/PackageToken")
                  }
                })
              } else {
                props.candidate.loginData.data.Message.can_see_resumes = true;
                setCanSeeResumes(true);
                localStorage.setItem('can_see_resumes', 'true');
                toast.success("Acquisto effettuato correttamente", { theme: "colored", autoClose: 8000 });
              }
            })
            .catch((err) => {
              console.log(err, "err")
              console.log(err.response);
            });
          
          
        } else if(result.isDismissed) {
          // do nothing
          
        } 
      });
      return;
    }
    setDetails(details);
    setJobId(id);
  };

  const redirectToJobFinder = () => {
    if(location.state.resultsType === "agents") {
      navigate("/agent/search", { state: { jobListData: [] } });
    } else {
      navigate("/company/candidate-finder", { state: { jobListData: [] } });
    }
    
  };

  return (
    <>
      <Header showModal={showModal} setShowModal={setShowModal} />
      <div className="container">
        <div className="new_research_btn mt-4 text-end">
          <button
            style={{ marginRight: "10px" }}
            className="btn btn_default btn_orange_outline"
            type="button"
            onClick={redirectToJobFinder}
          >
            Nuova ricerca
          </button>
          {props.candidate?.loginData?.data.Message.flag_company ? (
            <button
              className="btn btn_default btn_orange_outline"
              type="button"
              onClick={() => {
                navigate("/company/create-adv");
              }}
            >
              Crea nuovo annuncio
            </button>
          ) : (
            <></>
          )}
        </div>
        <div className="row justify-content-center">
          <div className="col-md-6 col-12">
            {jobs.length > 0 && (
              <div className="job_listing_sec">
                {/* <div className="container"> */}

                <div className="job_listing_blocks">
                  {jobs.map((job) => {
                    return (
                      <div
                        key={job.id}
                        className={`job_listing_block ${
                          (!props.candidate?.loginData?.data.Message.flag_company || !canSeeResumes) ? "blur_effect" : ""
                        }`}
                      >
                        <a
                          className="redirect_a"
                          onClick={() => redirectToJobDetails(job.id, job)}
                        >
                          <div className="job_listing_block_inner">
                            <div className="job_title">
                              <h2>
                                {(() => {
                                  if (user?.company) {
                                    return `${getValue(
                                      job.name || ""
                                    )} ${getValue(job.surname || "")}`;
                                  } else {
                                    return `${getValue(
                                      job.first_name || ""
                                    )} ${getValue(job.last_name || "")}`;
                                  }
                                })()}
                                {getValue(job.first_name || "")}{" "}
                                {getValue(job.last_name || "")}
                              </h2>
                            </div>
                            <div className="logo_detailbtn">
                              <div className="company_logo">
                                <img src={CompanyLogo} alt="company logo" />
                              </div>
                              <div className="detail_btn">
                                <button
                                  className="btn btn_default btn_green_outline"
                                  type="button"
                                  // onClick={() => redirectToJobDetails(job.id)}
                                >
                                  { location.state.resultsType === "employees" && "Dettagli candidato"}
                                  {location.state.resultsType === "agents" && "Dettagli agente"}
                                  {/*location.state.resultsType !== "employees" || location.state.resultsType !== "agents" ? t("job-list.button.save") : '' */}
                                </button>
                              </div>
                            </div>
                            {/* <div className="job_description">
                                                        <p>{getValue(job.job_description)}</p>
                                                    </div> */}
                            <div className="sector_name">
                              <p>
                                <strong>
                                  {user?.company ? "Settore" : "Name"}
                                  :&nbsp;&nbsp;
                                </strong>
                                {(() => {
                                  if (user?.company) {
                                    return getValue(
                                      job?.sector?.sectorName || ""
                                    );
                                  } else {
                                    return `${getValue(
                                      job?.title?.title || ""
                                    )} ${getValue(job.name || "")} ${getValue(
                                      job.surname || ""
                                    )}`;
                                  }
                                })()}
                              </p>
                            </div>
                            {user?.company ? (
                              <></>
                            ) : (
                              <div className="sector_name">
                                <p>
                                  <strong>cellphone:&nbsp;&nbsp;</strong>
                                  {getValue(job?.cellphone || "")}
                                </p>
                              </div>
                            )}
                            <div className="job_salary_address">
                              {/* <div className="job_salary">
                                                            <p>
                                                                <strong>Retribuzione:&nbsp;&nbsp;</strong>
                                                                {getValue(
                                                                    job?.salary?.fixedAmount
                                                                        ? `${job?.salary?.fixedAmount} ${job?.salary?.reccurency}`
                                                                        : job?.salary?.minAmount && job?.salary?.maxAmount
                                                                            ? `${job?.salary?.minAmount} - ${job?.salary?.maxAmount} ${job?.salary?.reccurency}`
                                                                            : ""
                                                                )}
                                                            </p>
                                                        </div> */}
                              <div className="job_address">
                                <p>
                                  <strong>Luogo:&nbsp;&nbsp;</strong>
                                  <span>
                                    {getValue(
                                      job?.address?.[0]
                                        ? `${
                                            job?.address?.[0]?.city
                                              ? job?.address?.[0]?.city
                                              : ""
                                          }${
                                            job?.address?.[0]?.province
                                              ? `, ${job?.address?.[0]?.province}`
                                              : ""
                                          }${
                                            job?.address?.[0]?.country
                                              ? `, ${job?.address?.[0]?.country}`
                                              : ""
                                          }`
                                        : ""
                                    )}
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className="required_education_skills">
                              <div className="required_education">
                                <p>
                                  {" "}
                                  {job.educationalLevel?.levelType ? (
                                    <strong>
                                      {(() => {
                                        if (user?.company) {
                                          return "Istruzione:";
                                        } else {
                                          return "Istruzione richiesta:";
                                        }
                                      })()}
                                      &nbsp;&nbsp;
                                    </strong>
                                  ) : (
                                    ""
                                  )}{" "}
                                  {getValue(
                                    job.educationalLevel?.levelType || ""
                                  )}
                                </p>
                              </div>
                              {/* <div className="required_skills">

                                                            {user && job.skills && job.skills.length > 0 ?
                                                                (<>
                                                                    <p>
                                                                        <strong>
                                                                            Competenze richieste:&nbsp;&nbsp;
                                                                        </strong>

                                                                        {
                                                                            job.skills.map((skill, index) => (
                                                                                <span key={index}>{skill.skillName},</span>
                                                                            ))
                                                                        }

                                                                    </p>
                                                                </>
                                                                ) : (
                                                                    ""
                                                                )}
                                                        </div> */}
                            </div>
                          </div>
                        </a>
                      </div>
                    );
                  })}
                </div>
                {/* </div> */}
              </div>
            )}
          </div>
          {JobId && user && (
            <div className="col-md-6 col-12">
              <CandidateJobsDetails id={JobId} user={user} detail={details} />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return { candidate: state.candidate, company: state.company };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ requestGetJobList, resetGetJobList, requestTwelveTokenSpent }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CandidateJobsList);
