import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Error from "./404";
import Home from "./Home";
import JobsDetails from "./JobsDetails";
import JobsList from "./JobsList";
import Register from "./register/Register";
import ThankYou from "./ThankYou";
import ThankYou1 from "../Candidate/ThankYou";
// import ThankYou1 from "../Candidate/ThankYou";
// import Login from './login/Login';
import ResumeForm from "./resume/ResumeForm";
import JobFinder from "./JobFinder";
import PrivateRoute from "./PrivateRoutes";
import UpdateResume from "./updateResume/UpdateResume";
import FranchisorRegister from "../Franchisor/register/Register";
import FranchisorRegister2 from "../Franchisor/register/Register2";
// import SocialRegister from './login/SocialRegister';

// ================================== Password Reset ===================================
// import PasswordReset from './forggot-password/Password-Reset';
import PasswordResetConfirm from "./forggot-password/Password-Reset-Confirm";
import PasswordResetComplete from "./forggot-password/Password-Reset-Complete";

// ==================================Candidate===================================
import CandidateRegister from "../Candidate/register/Register";
import Profile from "../Candidate/profile/Profile";

// ================================== Agent ===================================
import AgentRegister from "../Agent/register/Register";

// ================================== Franchisor ===================================
import ServiceRegister from "../Franchisor/register/Register";

// ================================== Company ===================================
import CompanyRegister from "../Company/register/Register";
import CandidateFinder from "../Company/jobs/CandidateFinder";
import CandidateJobsList from "../Company/jobs/Candidate-jobs-list";
import CandidateJobsDetails from "../Company/jobs/Candidate-jobs-details";
import CompanyProfile from "../Company/profile/Company-Profile";
import AdvertisementList from "../Company/Advertisement/AdvertisementList";
import AdvertisementCandidateList from "../Company/Advertisement/AdvertisementCandidateList";
import PackageToken from "../Company/PackageToken/PackageToken";
import CreditManagment from "../Company/Credit_management/Credit_management";
import FinalForm from "../Company/Create-and-ad/FinalForm";
import FranchisorProfile from "../Franchisor/profile/FranchisorProfile";
import AgentProfile from "../Agent/profile/AgentProfile";
import PayPalPayment from "../Company/Payment/PayPalPayment";
import AdvertisementDetails from "../Company/Advertisement/AdvertisementDetails";
import CVDetails from "../Company/Advertisement/CVDetails";
import CreaAnnuncio7Bis from "../Company/Advertisement/CreaAnnuncio7Bis";
import CandidateCVDetails from "../Company/jobs/Candidate-Resume-List";

// Extra Company Pages
import NotificationPreferences from "../ExtraCompanyPages/NotificationPreferences";
import DisplayName from "../ExtraCompanyPages/DisplayName";
import OfferedServices from "../ExtraCompanyPages/OfferedServices";

import ProfessionalRequirements from "../ExtraCompanyPages/ProfessionalRequirements";

import ProfessionalProfile from "../Components/ProfessionalProfile";

//api side working
// import Paypal from '../Company/payment-system/Paypal';
import Stripe from "../Company/payment-system/stripe/Stripe";
import PaymentButtons from "../Company/payment-system/PaymentButtons";
import StripeSession from "../Company/payment-system/stripe-session/StripeSession";

import OpportunityFinderAgent from "../Agent/opportunity-finder/OpportunityFinderAgent";
import FranchisorAuth from "../Franchisor/auth/FranchisorAuth";
import OpportunitiesListAgent from "../Agent/opportunities-list/OpportunitiesListAgent";
import SearchAgent from "../Agent/search/SearchAgent";
import SearchFranchisor from "../Franchisor/search-franchisor/SearchFranchisor";
import SearchFranchisor2 from "../Franchisor/search-franchisor/SearchFranchisor2";
import SearchResults from "../Franchisor/search-franchisor/SearchResults";

import SuccessPayment from "../Company/payment-system/SuccessPayment";

import SavedAds from "./SavedAds";
import Opportunities from "../Ads/Opportunities";
import Preventivo from "../ExtraCompanyPages/Preventivo";
import QuestionsTree from "../QuestionsTree/QuestionsTree";
import ReceivedQuotations from "../Ads/ReceivedQuotations";

function App() {
  const wrapPrivateRoute = (element, params) => {
    return <PrivateRoute params={params}>{element}</PrivateRoute>;
  };

  return (
    <BrowserRouter basename="/home">
      <Routes>
        <Route index element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<Register />} />
        {/* <Route path="/social-register" element={<SocialRegister />} /> */}
        <Route path="/thanks" element={<ThankYou />} />
        <Route path="/resume" element={<ResumeForm />} />
        {/* <Route path="/job-details/:id" element={<JobsDetails />} /> */}
        <Route path="/jobs-list" element={<JobsList />} />
        <Route path="/job-finder" element={<JobFinder />} />

        {/* password reset  */}
        {/* <Route path="/password-reset" element={<PasswordReset />} /> */}
        <Route
          path="/password-reset-confirm"
          element={<PasswordResetConfirm />}
        />
        <Route
          path="/password-reset-complete"
          element={<PasswordResetComplete />}
        />

        {/* <Route path="/job-details/:id" element={<JobsDetails />} />
          <Route path="/jobs-list" element={<JobsList />} /> */}
        <Route
          path="/candidate/profile"
          element={wrapPrivateRoute(<Profile />)}
        />
        <Route
          path="/update-resume"
          element={wrapPrivateRoute(<UpdateResume />)}
        />
        <Route
          path="/job-details/:id"
          element={wrapPrivateRoute(<JobsDetails />)}
        />
        <Route path="/saved-ads" element={<SavedAds />} />

        {/* =======================Company========================= */}
        <Route
          path="/company/profile"
          element={wrapPrivateRoute(<CompanyProfile />)}
        />
        <Route path="/company/candidate-finder" element={<CandidateFinder />} />
        <Route
          path="/company/candidate-jobs-list"
          element={<CandidateJobsList />}
        />
        <Route
          path="/company/candidate-jobs-details"
          element={wrapPrivateRoute(<CandidateJobsDetails />)}
        />
        <Route
          path="/company/PackageToken"
          element={wrapPrivateRoute(<PackageToken />)}
        />
        <Route
          path="/company/payment"
          element={wrapPrivateRoute(<PayPalPayment />)}
        />
        <Route path="/company/payment/stripe" element={<Stripe />} />
        <Route
          path="/company/payment/stripe-session"
          element={<StripeSession />}
        />
        <Route path="/company/payment-system" element={<PaymentButtons />} />
        <Route path="/company/payment/success" element={<SuccessPayment />} />
        <Route
          path="/company/Creditmanagement"
          element={wrapPrivateRoute(<CreditManagment />)}
        />
        <Route
          path="/company/advertisement-details/:id"
          element={wrapPrivateRoute(<AdvertisementDetails />)}
        />
        <Route
          path="/company/advertisement-list/:id/candidates"
          element={wrapPrivateRoute(<AdvertisementCandidateList />)}
        />
        <Route
          path="/company/cv-details/:id"
          element={wrapPrivateRoute(<CVDetails />)}
        />
        <Route
          path="/company/CreaAnnuncio7_bis"
          element={<CreaAnnuncio7Bis />}
        />
        <Route path="/company/create-adv" element={<FinalForm />} />

        {/* <Route path="/login" element={<Login />} /> */}

        <Route path="*" element={<Error />} />

        {/*================================ Candidate ========================*/}
        <Route
          path="/candidate"
          element={<Navigate to="/candidate/register" replace />}
        />
        <Route path="/candidate/register" element={<CandidateRegister />} />
        <Route path="/candidate/thanks" element={<ThankYou1 />} />

        {/*================================ Agent ========================*/}
        <Route
          path="/agent"
          element={<Navigate to="/agent/register" replace />}
        />
        <Route path="/agent/register" element={<AgentRegister />} />
        <Route
          path="/agent/profile"
          element={wrapPrivateRoute(<AgentProfile />, {
            userType: "agent",
            from: "/agent/profile",
          })}
        />
        <Route
          path="/agent/opportunity-finder"
          element={<OpportunityFinderAgent />}
        />
        <Route
          path="/agent/opportunities-list"
          element={<OpportunitiesListAgent />}
        />
        <Route path="/agent/search" element={<SearchAgent />} />
        {/*============================ Franchisor ========================*/}
        <Route
          path="/franchisor"
          element={<Navigate to="/franchisor/register" replace />}
        />
        <Route path="/franchisor/search" element={<SearchFranchisor />} />
        <Route path="/franchisor/search2" element={<SearchFranchisor2 />} />
        <Route path="/franchisor/register" element={<FranchisorRegister />} />

        {/* Che in realta' e' la pagina di creazione ad */}
        <Route path="/franchisor/register2" element={<FranchisorRegister2 />} />

        <Route path="/franchisor/search-results" element={<SearchResults />} />
        <Route
          path="/franchisor/profile"
          element={wrapPrivateRoute(<FranchisorProfile />)}
        />

        {/*============================ Company ========================*/}
        <Route
          path="/company"
          element={<Navigate to="/company/register" replace />}
        />
        <Route path="/company/register" element={<CompanyRegister />} />
        <Route path="/company/candidate-finder" element={<CandidateFinder />} />
        <Route
          path="/company/advertisement-list"
          element={<AdvertisementList />}
        />
        <Route path="/offered-services" element={<OfferedServices />} />
        {/* <Route path="/company/PackageToken" element={<PackageToken />} /> */}
        {/* <Route path="/company/Creditmanagement" element={<CreditManagment />} /> */}
        <Route path="/company/create-adv" element={<FinalForm />} />
        <Route path="/opportunities" element={<Opportunities />} />
        <Route path="/preventivo" element={<Preventivo />} />

        {/* <Route path="/company/candidate-jobs-list" element={<CandidateJobsList />} />
        <Route path="/company/candidate-jobs-details" element={<CandidateJobsDetails />} /> */}

        <Route path="/notifications" element={<NotificationPreferences />} />
        <Route path="/display-name" element={<DisplayName />} />

        <Route
          path="/professional-requirements"
          element={<ProfessionalRequirements />}
        />

        <Route path="/professional-profile" element={<ProfessionalProfile />} />

        <Route path="/questions-tree" element={<QuestionsTree />} />

        <Route path="/received-quotations" element={<ReceivedQuotations />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
