import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import {
  requestLogin,
  requestGetList,
  requestGetLocation,
  requestGetSectorList,
  requestGetCVList,
  userLogout
} from '../../Redux/actions'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import Breadcrumbs from '../../Components/Breadcrumbs'
import Select, { createFilter } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { useNavigate } from 'react-router-dom'
import loaderLogo from "../../images/submit.gif";

function CandidateFinder (props) {
  const [data, setData] = useState({
    country: 'Italia'
  })
  const [countryData, setCountryData] = useState([])
  const [municipalities, setMunicipalities] = useState([])
  const [provinceData, setProvinceData] = useState([])
  const [provinceFilterData, setProvinceFilterData] = useState([])
  const [municipalityFilterData, setMunicipalityFilterData] = useState([])

  const [regioneData, setRegioneData] = useState([])
  const [valueRang, setValueRange] = useState(20)
  const [location, setLocation] = useState({ lat: '', lon: '' })
  //======================sector and sub sector ===========================
  const [sectorsLevel, setSectorsLevel] = useState([])
  const [subSubSectorsLevel, setSubSubSectorsLevel] = useState([])

  const [selectedOptionsSector, setSelectedOptionsSector] = useState([
    {
      submenu: '',
      subSubmenu: ''
    }
  ])
  const [isLoading, setIsLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    props.requestGetList()
    props.requestGetLocation()
    props.requestGetSectorList()
  }, [])

  const isClearable = true
  const isSearchable = true
  const matchFromStart = true

  const filterConfig = {
    matchFrom: matchFromStart ? 'start' : 'any'
  }

  useEffect(() => {
    let levelData = props.candidate.getlistData
    if (levelData !== undefined) {
      if (levelData?.data?.Status === 'Success') {
        const processedCountries = levelData.data.Message.countries.map(
          item => {
            const name = item.nativeCountryName
            return name
          }
        )
        const uniqueCountries = [...new Set(processedCountries)]
        const uniqueCountryData = uniqueCountries.map(name => ({
          nativeCountryName: name
        }))
        setCountryData(uniqueCountryData)
      }
    }
  }, [props.candidate.getlistData])

  const sortedCountryData = [...countryData].sort((a, b) => {
    if (a.nativeCountryName === 'Italia') return -1
    if (b.nativeCountryName === 'Italia') return 1
    return 0
  })

  useEffect(() => {
    const locationData = props.candidate.getlocation
    if (locationData !== undefined) {
      if (locationData?.data?.Status === 'Success') {
        const municipalities = locationData.data.Message.municipalities

        const uniqueRegiones = [
          ...new Map(
            municipalities.map(item => [item.regione.name, item])
          ).values()
        ]
        const transformedRegioneData = uniqueRegiones.map((item, index) => ({
          value: index,
          label: item.regione.name,
          code: item.regione.code
        }))
        setRegioneData(
          transformedRegioneData.sort((a, b) => a.label.localeCompare(b.label))
        )

        const uniqueProvinces = [
          ...new Map(
            municipalities.map(item => [item.provincia.name, item])
          ).values()
        ]
        setProvinceData(uniqueProvinces)

        const uniqueMunicipalities = [
          ...new Map(municipalities.map(item => [item.name, item])).values()
        ]
        setMunicipalities(uniqueMunicipalities)

        const allProvinces = uniqueProvinces.map((item, index) => ({
          value: index,
          label: item.provincia.name,
          code: item.provincia.code
        }))
        setProvinceFilterData(allProvinces)

        const allCities = uniqueMunicipalities.map((item, index) => ({
          value: index,
          label: item.name,
          code: item.code
        }))
        setMunicipalityFilterData(allCities)
      }
    }
  }, [props.candidate.getlocation])

  //=============sector and sub sector =====================

  useEffect(() => {
    const sectorsLevelData = props.candidate.SectorData
    if (sectorsLevelData?.data?.Status === 'Success') {
      const uniqueSectors = {}

      sectorsLevelData.data.Message.forEach(sector => {
        if (!uniqueSectors[sector.sectorName]) {
          uniqueSectors[sector.sectorName] = {
            value: sector.id,
            label: sector.sectorName
          }
        }
      })
      const transformedSectorlevelData = Object.values(uniqueSectors)
      setSectorsLevel(transformedSectorlevelData)

      const filteredSubMenuOptions = sectorsLevelData.data.Message.filter(
        level => level.subSector
      ).map(level => ({
        sectorName: level.sectorName,
        subSector: level.subSector,
        label: level.subSector
      }))

      setSubSubSectorsLevel(filteredSubMenuOptions)
    }
  }, [props.candidate.SectorData])

  const onChangeData = e => {
    const { name, value } = e.target

    setData(prevData => {
      if (name === 'country') {
        return {
          ...prevData,
          country: value,
          regione: '',
          province: '',
          city: ''
        }
      }
      return {
        ...prevData,
        [name]: value
      }
    })
  }

  const handleOnChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta

    if (name === 'regione') {
      if (!selectedOption) {
        const allProvinces = provinceData.map((item, index) => ({
          value: index,
          label: item.provincia.name,
          code: item.provincia.code
        }))
        setProvinceFilterData(allProvinces)

        const allCities = municipalities.map((item, index) => ({
          value: index,
          label: item.name,
          code: item.code
        }))
        setMunicipalityFilterData(allCities)

        setData(prevData => ({
          ...prevData,
          regione: '',
          province: '',
          city: ''
        }))
      } else {
        const filteredProvinces = provinceData.filter(
          i => i.regione.code === selectedOption?.code
        )
        const transformedProvinceData = filteredProvinces.map(
          (item, index) => ({
            value: index,
            label: item.provincia.name,
            code: item.provincia.code
          })
        )

        setProvinceFilterData(transformedProvinceData)

        const filteredMunicipalities = municipalities.filter(
          i => i.regione.code === selectedOption?.code
        )

        const transformedMunicipalitiesData = filteredMunicipalities.map(
          (item, index) => ({
            value: index,
            label: item.name,
            code: item.code
          })
        )
        setMunicipalityFilterData(transformedMunicipalitiesData)

        setData(prevData => ({
          ...prevData,
          [name]: selectedOption ? selectedOption.label : '',
          province: '',
          city: ''
        }))
      }
    } else if (name === 'province') {
      if (!selectedOption) {
        const allCities = municipalities.map((item, index) => ({
          value: index,
          label: item.name,
          code: item.code
        }))
        setMunicipalityFilterData(allCities)

        setData(prevData => ({
          ...prevData,
          province: '',
          city: ''
        }))
      } else {
        const filteredMunicipalities = municipalities.filter(
          i => i.provincia.code === selectedOption?.code
        )
        const transformedMunicipalitiesData = filteredMunicipalities.map(
          (item, index) => ({
            value: index,
            label: item.name,
            code: item.code
          })
        )

        setMunicipalityFilterData(transformedMunicipalitiesData)
        setData(prevData => ({
          ...prevData,
          [name]: selectedOption ? selectedOption.label : '',
          city: ''
        }))
      }
    } else {
      setData(prevData => ({
        ...prevData,
        [name]: selectedOption ? selectedOption.label : ''
      }))
    }
  }

  useEffect(() => {
    console.log(navigator)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          console.log(position, 'position')
          setLocation({
            lat: position.coords.latitude,
            lon: position.coords.longitude
          })
        },
        error => {
          // toast.error(error.message, { theme: "colored", autoClose: 8000 });
          console.error('Error getting location: ', error)
        }
      )
    } else {
      console.error('Geolocation is not supported by this browser.')
    }
  }, [])

  const handleSubmit = event => {
    event.preventDefault()

    const token = localStorage.getItem('token');

    console.log(valueRang, "valueRang")

    let requestData = { flag_employee: true };
    if(data.country) { requestData.address__country = data.country } 
    if(data.regione) { requestData.address__state = data.regione } 
    if(data.city) { requestData.address__city = data.city } 
    if(data.province) { requestData.address__province = data.province } 
    if(data.professional) { requestData.sector__sectorName = data.professional } 
    if(data.specialization) { requestData.subsector__subsector_name = data.specialization } 
    if(valueRang) { requestData.rad = valueRang } 
    if (valueRang !== "0") {
      requestData.lat = location.lat;
      requestData.lon = location.lon;
    } 

    const allFieldsEmpty = Object.values(requestData).every(
      value => value === '' || value === undefined
    )

    if (allFieldsEmpty) {
      Swal.fire({
        title: 'Compila almeno un cmapo',
        timer: 2000,
        icon: 'warning'
      })
      return
    }

    if (token) {
      console.log(token)
      requestData.token = token
    }

    props.requestGetCVList({ data: requestData });
    setSubmitLoading(true);
  }

  useEffect(() => {
    props.company.cvListData = undefined;
  }, [])

  useEffect(() => {
    let cvFindData = props.company.cvListData
    if (cvFindData !== undefined) {
      if (cvFindData?.data?.Status === 'Success') {
        if (
          Array.isArray(cvFindData.data.Message) &&
          cvFindData.data.Message.length === 0
        ) {
          //toast.error("No CV found", { theme: "colored", autoClose: 8000 });
          Swal.fire({
            title: 'Nessun Curriculum Vitae trovato',
            icon: 'warning'
          })
        } else {
          setSubmitLoading(false);
          navigate('/company/candidate-jobs-list', {
            state: {
              jobListData: cvFindData?.data?.Message,
              resultsType: 'employees'
            }
          })
          toast.success(`Sono stati trovati ${cvFindData.data.Message.length} annunci`, {
            theme: 'colored',
            autoClose: 8000
          })
        }
        props.company.cvListData = undefined
      } else {
        /*toast.error(cvFindData.data.Message, {
          theme: "colored",
          autoClose: 8000,
        });*/
        Swal.fire({
          title: cvFindData.data.Message,
          icon: 'warning'
        })
        props.company.cvListData = undefined
      }
    }
  }, [props.company.cvListData])

  const handleChange = event => {
    setValueRange(event.target.value)
  }

  const handleOnSectorChange = e => {
    setIsLoading(true)
    const value = e ? e.label : ''
    setSelectedOptionsSector(prevOptions => ({
      ...prevOptions,
      submenu: value,
      subSubmenu: ''
    }))

    setData(prevData => ({
      ...prevData,
      professional: value,
      specialization: ''
    }))

    setIsLoading(false)
  }

  const handleSubsubmenuChange = e => {
    const value = e ? e.label.trim() : ''
    setSelectedOptionsSector(prevOptions => ({
      ...prevOptions,
      subSubmenu: value
    }))
    setData(prevData => ({
      ...prevData,
      specialization: value
    }))
  }

  const selectedSubmenuOption = subSubSectorsLevel.filter(
    option => option.sectorName === selectedOptionsSector?.submenu
  )

  const hasSubSubmenuOptions =
    selectedSubmenuOption && selectedSubmenuOption.length > 0

  return (
    <>
      <Header />
      <Breadcrumbs />
      <div className='seekng_job_sec'>
        <div className='container'>
          <div className='seekng_job_title_note'>
            <div className='seekng_job_title' style={{ flex: 'unset' }}>
              <h2>Cerchi un candidato?</h2>
            </div>
            {props.candidate?.loginData?.data.Message.flag_company ? (
              <button
                className='btn btn_default btn_orange_outline'
                type='button'
                onClick={() => {
                  navigate('/company/create-adv')
                }}
              >
                Crea nuovo annuncio
              </button>
            ) : (
              <></>
            )}
          </div>
          <div className='seeking_job_inner'>
            <div className='seeking_job_form'>
              <div className='seeking_form_left'>
                <h3>{t('job_finder.tab-first.title')}</h3>
                <form className='row g-3'>
                  <div className='col-12'>
                    <label htmlFor='professional'>
                      {t('job_finder.tab-first.profession')}
                    </label>
                    <CreatableSelect
                      className='form-control'
                      name='professional'
                      value={
                        selectedOptionsSector.submenu
                          ? {
                              label: selectedOptionsSector.submenu,
                              value: selectedOptionsSector.submenu
                            }
                          : null
                      }
                      options={sectorsLevel}
                      onChange={handleOnSectorChange}
                      isClearable={isClearable}
                      isLoading={isLoading}
                      placeholder={t('job_finder.tab-first.profession')}
                      filterOption={createFilter(filterConfig)}
                    />
                  </div>
                  {selectedOptionsSector?.submenu && hasSubSubmenuOptions ? (
                    <div className='col-12'>
                      <label>{t('job_finder.tab-first.Specialization')}</label>
                      <Select
                        className='form-control'
                        name='specialization'
                        options={selectedSubmenuOption}
                        value={
                          selectedOptionsSector.subSubmenu
                            ? {
                                label: selectedOptionsSector.subSubmenu,
                                value: selectedOptionsSector.subSubmenu
                              }
                            : null
                        }
                        isClearable={isClearable}
                        isSearchable={isSearchable}
                        onChange={handleSubsubmenuChange}
                        filterOption={createFilter(filterConfig)}
                        placeholder={t('job_finder.tab-first.Specialization')}
                      />
                    </div>
                  ) : (
                    <div className='col-12'>
                      <label htmlFor='specialization' className='form-label'>
                        {t('job_finder.tab-first.Specialization')}
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        name='specialization'
                        id='specialization'
                        onChange={onChangeData}
                        value={data.specialization || ''}
                        placeholder={t('job_finder.tab-first.Specialization')}
                      />
                    </div>
                  )}
                </form>
              </div>
              <div className='seeking_form_right'>
                <h3>{t('job_finder.tab-second.title')}</h3>
                <form className='row g-3'>
                  <div className='col-12'>
                    <label htmlFor='country' className='form-label'>
                      {t('location.country')}
                    </label>
                    <select
                      className='form-control'
                      placeholder={t('location.country')}
                      id='country'
                      name='country'
                      value={data.country}
                      defaultValue={'Italia'}
                      onChange={onChangeData}
                    >
                      <option value=''>{t('location.country')}</option>
                      {sortedCountryData.map((option, index) => (
                        <option key={index} value={option.nativeCountryName}>
                          {option.nativeCountryName}
                        </option>
                      ))}
                    </select>
                  </div>

                  {data?.country?.length > 0 ? (
                    <>
                      {data.country === 'Italia' ? (
                        <>
                          <div className='col-12'>
                            <label htmlFor='regione' className='form-label'>
                              {t('location.regione')}
                            </label>
                            <Select
                              className='form-control'
                              name='regione'
                              options={regioneData}
                              isClearable={isClearable}
                              isSearchable={isSearchable}
                              onChange={(option, actionMeta) =>
                                handleOnChange(option, actionMeta)
                              }
                              filterOption={createFilter(filterConfig)}
                              placeholder={t('location.regione')}
                            />
                          </div>

                          <div className='col-12'>
                            <label htmlFor='province' className='form-label'>
                              {t('location.province')}
                            </label>
                            <Select
                              className='form-control'
                              name='province'
                              value={
                                data.province
                                  ? {
                                      label: data.province,
                                      value: data.province
                                    }
                                  : ''
                              }
                              options={provinceFilterData}
                              isClearable={isClearable}
                              isSearchable={isSearchable}
                              onChange={(option, actionMeta) =>
                                handleOnChange(option, actionMeta)
                              }
                              filterOption={createFilter(filterConfig)}
                              placeholder={t('location.province')}
                            />
                          </div>

                          <div className='col-12'>
                            <label htmlFor='city' className='form-label'>
                              {t('location.city')}
                            </label>
                            <Select
                              className='form-control'
                              name='city'
                              value={
                                data.city
                                  ? { label: data.city, value: data.city }
                                  : ''
                              }
                              options={municipalityFilterData}
                              isClearable={isClearable}
                              isSearchable={isSearchable}
                              onChange={(option, actionMeta) =>
                                handleOnChange(option, actionMeta)
                              }
                              filterOption={createFilter(filterConfig)}
                              placeholder={t('location.city')}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className='col-12'>
                            <label htmlFor='regione' className='form-label'>
                              {t('location.regione')}
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              name='regione'
                              id='regione'
                              value={data.regione || ''}
                              onChange={onChangeData}
                              placeholder={t('location.regione')}
                            />
                          </div>
                          <div className='col-12'>
                            <label htmlFor='province' className='form-label'>
                              {t('location.province')}
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              name='province'
                              id='province'
                              value={data.province || ''}
                              onChange={onChangeData}
                              placeholder={t('location.province')}
                            />
                          </div>
                          <div className='col-12'>
                            <label htmlFor='city' className='form-label'>
                              {t('location.city')}
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              name='city'
                              id='city'
                              value={data.city || ''}
                              onChange={onChangeData}
                              placeholder={t('location.city')}
                            />
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <div className='col-12'>
                        <label htmlFor='regione' className='form-label'>
                          {t('location.regione')}
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          name='regione'
                          id='regione'
                          value={data.regione || ''}
                          onChange={onChangeData}
                          placeholder={t('location.regione')}
                        />
                      </div>
                      <div className='col-12'>
                        <label htmlFor='province' className='form-label'>
                          {t('location.province')}
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          name='province'
                          id='province'
                          value={data.province || ''}
                          onChange={onChangeData}
                          placeholder={t('location.province')}
                        />
                      </div>
                      <div className='col-12'>
                        <label htmlFor='city' className='form-label'>
                          {t('location.city')}
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          name='city'
                          id='city'
                          value={data.city || ''}
                          onChange={onChangeData}
                          placeholder={t('location.city')}
                        />
                      </div>
                    </>
                  )}

                  <div className='col-12'>
                    <label htmlFor='customRange1' className='form-label'>
                      In un raggio di{' '}
                      <span className='small_label'>
                        (km dalla mia posizione)
                      </span>
                    </label>
                    <div className='input_rangbar_value'>
                      <input
                        type='range'
                        min='0'
                        max='100'
                        step='1'
                        value={valueRang}
                        onChange={handleChange}
                      />
                      <span>{valueRang}</span>
                    </div>
                    <p className='mt-2'>
                      Selezionando 0 km non verrà usata la posizione attuale
                      come parametro di ricerca
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className='seeking_form_btn'>
            <div
              className='btn btn_default btn_orange_outline'
              onClick={handleSubmit}
            >
              {submitLoading ? 
                <img style={{width:'100%', height:'20px', objectFit: 'contain'}} src={loaderLogo} />
                :
                t('job_finder.button.save')
              }
              
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

const mapStateToProps = state => {
  return { company: state.company, candidate: state.candidate }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      requestLogin,
      requestGetList,
      requestGetLocation,
      requestGetSectorList,
      requestGetCVList,
      userLogout
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(CandidateFinder)
