import React, { useEffect } from "react";
import Header from "../../../Components/Header";
import Footer from "../../../Components/Footer";
import Select, { createFilter } from "react-select";
import ContractType from "./ContractType";
import ContractTypeAgent from "./ContractTypeAgent";
import Availabilities from "./Availabilities";
import BackButton from "../common/BackButton";
import NextButton from "../common/NextButton";

function CreaAnnuncioFour(props) {
    const {
        nextStep,
        prevStep,
        CreatAd_Four,
        errorCreatAd_Four,
        contractTypesData,
        contractTypesAgentData,
        availabilitiesData,
        filterConfig,
        handleOnCreatAd_Four,
        validateDuration,
        validateAvailability,
        validateContractType,
        validateContractTypeAgent,
        contractTypeRef,
        contractTypeAgentRef,
        contractDurationRef,
        durationStringRef,
        availabilityRef,
        mystyle,
        t,
        handleCreateFourType,
        isClearable,
        isSearchable,
        selectedOptionsAvailabilityType,
        handleCreateAvailability,
        selectedOptionsContractType,
        selectedOptionsContractTypeAgent,
        handleContractDuration,
        error,
        setError,
        newAdvertisementId,
        setStepParam
    } = props;

    const comboOptions = [
        {
            label: "settimana",
            value: "settimana",
        },
        {
            label: "mese",
            value: "mese",
        },
        {
            label: "anno",
            value: "anno",
        },
    ];


    useEffect(() => {
        const focusField = () => {
            if (errorCreatAd_Four.contract_type && contractTypeRef?.current) {
                contractTypeRef.current.focus();
            } else if (errorCreatAd_Four.contract_duration_n && contractDurationRef?.current) {
                contractDurationRef.current.focus();
            } else if (errorCreatAd_Four.contract_duration_period && durationStringRef?.current) {
                durationStringRef.current.focus();
            } else if (errorCreatAd_Four.availability && availabilityRef?.current) {
                availabilityRef.current.focus();
            }
        };

        if (error) {
            focusField();
            setError(false);
        }
    }, [
        error,
        errorCreatAd_Four,
        contractTypeRef,
        contractDurationRef,
        durationStringRef
    ]);

    return (
        <>
            <Header />
            <div className="seekng_job_sec create-an-ad">
                <div className="container">
                    <form>
                        <div className="seekng_job_title_note">
                            <div className="seekng_job_title">
                                <h2>
                                {setStepParam != null ? "Modifica l'annuncio"
                                     : (
                                        props.ad_type == 'Agente' ? 'Inserisci un nuovo annuncio per la ricerca di agenti'
                                        :
                                        "Inserisci un nuovo annuncio per la ricerca di dipendenti"
                                        )
                                    }
                                </h2>
                                <h6>Informazioni sul contratto</h6>
                            </div>
                        </div>
                        <div className="seeking_job_inner">
                            <div className="seeking_job_form">
                                {props.ad_type == 'Agente' ? 
                                (
                                <ContractTypeAgent
                                    CreatAd_Four={CreatAd_Four}
                                    errorCreatAd_Four={errorCreatAd_Four}
                                    contractTypesAgentData={contractTypesAgentData}
                                    validateContractTypeAgent={validateContractTypeAgent}
                                    handleCreateFourType={handleCreateFourType}
                                    contractTypeAgentRef={contractTypeAgentRef}
                                    mystyle={mystyle}
                                    isClearable={isClearable}
                                    isSearchable={isSearchable}
                                    selectedOptionsContractType={selectedOptionsContractType}
                                    filterConfig={filterConfig}
                                />
                                )
                            :
                            (
                            <ContractType
                                    CreatAd_Four={CreatAd_Four}
                                    errorCreatAd_Four={errorCreatAd_Four}
                                    contractTypesData={contractTypesData}
                                    validateContractType={validateContractType}
                                    handleCreateFourType={handleCreateFourType}
                                    contractTypeRef={contractTypeRef}
                                    mystyle={mystyle}
                                    isClearable={isClearable}
                                    isSearchable={isSearchable}
                                    selectedOptionsContractType={selectedOptionsContractType}
                                    filterConfig={filterConfig}
                                />
                            )}
                                {(CreatAd_Four?.contract_type?.find(option => option.contract_name !== 'Indeterminato') || CreatAd_Four.contract_type.length > 1) && (
                                <div className="seeking_form_left mt-30">
                                    <div className="row g-3">
                                        <div className="col-12">
                                            <label className="form-label">Durata del contratto</label>
                                            <div className="d-flex flex-wrap flex-sm-nowrap contact-duration">
                                                <input
                                                    className={`form-control ${errorCreatAd_Four?.contract_duration_n
                                                        ? "error"
                                                        : ""
                                                        }`}
                                                    type="text"
                                                    name="contract_duration_n"
                                                    placeholder="Durata"
                                                    value={CreatAd_Four?.contract_duration_n || ""}
                                                    ref={contractDurationRef}
                                                    onChange={handleOnCreatAd_Four}
                                                    onBlur={validateDuration}
                                                />

                                                <Select
                                                    className={`form-control select_dropdown ${CreatAd_Four?.contract_duration_n?.length === 0 && errorCreatAd_Four?.contract_duration_period
                                                        ? "error"
                                                        : ""
                                                        }`}
                                                    name="contract_duration_period"
                                                    options={comboOptions}
                                                    placeholder="Seleziona"
                                                    onChange={handleContractDuration}
                                                    onBlur={validateDuration}
                                                    value={CreatAd_Four?.contract_duration_period ? {
                                                        label: `${CreatAd_Four.contract_duration_period}`, value: `${CreatAd_Four.contract_duration_period}`,
                                                    } : null}
                                                    ref={durationStringRef}
                                                    filterOption={createFilter(filterConfig)}
                                                    isSearchable={isSearchable}
                                                    isClearable={isClearable}
                                                />
                                            </div>
                                            {CreatAd_Four.contract_duration_n?.length === 0 && errorCreatAd_Four?.contract_duration_n && (
                                                <div style={mystyle}>
                                                    {errorCreatAd_Four.contract_duration_n}
                                                </div>
                                            )}
                                            {CreatAd_Four.contract_duration_period?.length === 0 && errorCreatAd_Four?.contract_duration_period && (
                                                <div style={mystyle}>
                                                    {errorCreatAd_Four.contract_duration_period}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                )}
                                <Availabilities
                                    errorCreatAd_Four={errorCreatAd_Four}
                                    availabilitiesData={availabilitiesData}
                                    availabilityRef={availabilityRef}
                                    validateAvailability={validateAvailability}
                                    handleCreateFourType={handleCreateFourType}
                                    mystyle={mystyle}
                                    isClearable={isClearable}
                                    isSearchable={isSearchable}
                                    selectedOptionsAvailabilityType={selectedOptionsAvailabilityType}
                                    handleCreateAvailability={handleCreateAvailability}
                                    filterConfig={filterConfig}
                                />
                            </div>
                        </div>
                        <div className="seeking_form_btn">
                            <BackButton
                                prevStep={prevStep}
                            />
                            <NextButton
                                nextStep={nextStep}
                            />
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default CreaAnnuncioFour;