import { useEffect, useState } from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  requestCompanyDetails,
  requestGetJobListUserId,
  requestCandidateProfile,
  requestGetCandidate
} from '../../Redux/actions'
import loaderLogo from '../../images/Iphone-spinner-2.gif'
import { toast } from 'react-toastify';
import { useNavigate, useParams } from "react-router-dom";
import axios from 'axios';

function AdvertisementCandidateList (props) {
  const [jobList, setJobList] = useState([])
  const [savedListLoader, setSavedListLoader] = useState(true)
  const [companyId, setCompanyId] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const token = localStorage.getItem('token')
    const userType = localStorage.getItem('userType')
    const username = atob(localStorage.getItem('username'))
    if (token) {
      
        axios.get(`${process.env.REACT_APP_API_HOST}wideer/ads/`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            params: {
                id: id
            }
        })
        .then(response => {
            if(response.data.Status === "Success") {
                const candidatesList = response.data.Message[0].candidates;
                setSavedListLoader(false);
                setJobList(candidatesList);
            }
        })
      
    }
  }, [])

  
  const handleSearchChange = event => {
    setSearchQuery(event.target.value)
  }

  const formatDate = dateString => {
    if (!dateString) return ''
    const date = new Date(dateString)
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear()
    return `${day}/${month}/${year}`
  }

  const formatDate1 = dateString => {
    const date = new Date(dateString)
    if (isNaN(date.getTime())) return ''
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear()
    return `${day}/${month}/${year}`
  }


  const redirectOnDetails = (id) => {
    if(localStorage.getItem('userType') === 'company') {
      navigate("/company/cv-details/" + id, {state: jobList});
    } else {
      navigate("/company/advertisement-details/" + id);
    }
}


  return (
    <>
      <Header />
      <div className='advertisement_listing'>
        <div className='container'>
          <div className='advertisement_title'>
            
              <h3>Lista candidati per l'annuncio</h3>
           
          </div>
          <div
            className='advertisement_listing_inner position-relative'
            style={{ minHeight: 'calc(100vh - 280px)' }}
          >
            {savedListLoader ? (
              <div className='loader'>
                <div className='loader_inner'>
                  <img src={loaderLogo} />
                </div>
              </div>
            ) : (
              <>
                <div className='search_ads'>
                  <form>
                    <div className='row g-4'>
                      <div className='form-group col-md-6 col-12'>
                        <input
                          type='text'
                          className='form-control'
                          placeholder={localStorage.getItem('userType') === 'company' ? 'Ricerca CV' : 'Ricerca annunci' }
                          id='search'
                          name='search'
                          value={searchQuery}
                          onChange={handleSearchChange}
                        />
                      </div>
                    </div>
                  </form>
                </div>

                <div className='advertisement_table table-responsive'>
                  <table className='table'>
                    <thead>
                      <tr>
                        {localStorage.getItem('userType') === 'company'
                        ?
                        (
                          <>
                        <th scope='col'>Nome candidato</th>    
                        <th scope='col'>Data di nascita</th>
                        <th scope='col'>Settore</th>
                        <th scope='col'>Sottosettore</th>
                        <th scope='col'>Telefono</th>
                        </>
                        )
                        :
                        (
                          <>
                        <th scope='col'>Data di pubblicazione</th>
                        <th scope='col'>Titolo</th>
                        <th scope='col'>Azienda</th>
                        <th scope='col'>Settore</th>
                        <th scope='col'>Scadenza annuncio</th>
                        </>
                        )
                        }
                        
                      </tr>
                    </thead>
                    <tbody>
                      {
                      
                        jobList.map((item, index) => (
                          <tr key={index}>
                             
                              <td>
                                <a
                                  className='title'
                                  onClick={() => redirectOnDetails(item.id)}
                                >
                                  {item.name} {item.surname}
                                </a>
                              </td>
                              <td>
                                {formatDate1(item.birthdate)}
                              </td>
                              <td>
                              {item.sector.sectorName}
                              </td>
                              <td>
                              {item.subsector.subsector_name}
                              </td>
                              <td>
                                {item.cellphone}
                              </td>
                            </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

const mapStateToProps = state => {
  return { candidate: state.candidate, company: state.company }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      requestCompanyDetails,
      requestGetJobListUserId,
      requestCandidateProfile,
      requestGetCandidate
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(AdvertisementCandidateList)
