import React, { useEffect, useState } from 'react'
import Header from '../../../../Components/Header'
import Footer from '../../../../Components/Footer'
import BackButton from '../../common/BackButton'
import NextButton from '../../common/NextButton'
import Select, { createFilter } from 'react-select';
import requestGetProducts from "../../../../Redux/actions";
import axios from 'axios';

function CreaAnnuncioFiveAgent (props) {
  const {
    nextStep,
    prevStep,
    CreatAd_Five,
    setCreatAd_Five,
    errorCreatAd_Five,
    additionalWagesData,
    benefitsData,
    handleInputChange,
    filterConfig,
    mystyle,
    setErrorCreatAd_Five,
    t,
    handleCreateFiveAdditionalWages,
    handleCreateFiveBenefits,
    handleSectorChange,
    validateForFixAmount,
    validateForSalaryRecurrence,
    validateForMinAmount,
    validateForMaxAmount,
    validateAdditionalWages,
    validateBenefit,
    isClearable,
    isSearchable,
    selectedOptionsBenefits,
    selectedOptionsAdditionalWages,
    selectedOptionsSector,
    minAmountRef,
    maxAmountRef,
    fixedAmountRef,
    salaryRecurrenceRef,
    additionalWagesRef,
    benefitRef,
    error,
    setError,
    newAdvertisementId,
    setStepParam
  } = props

  const [sectorList, setSectorList] = useState([]);



  useEffect(() => {
    const products = axios
    .get(`${process.env.REACT_APP_API_HOST}wideer/getLists/getSectors`)
    .then((response) => {
        console.log(response);
        
        setSectorList(response)
    })
    .catch((err) => {
        console.log(err.response);
    });
   
  }, []);

  useEffect(() => {
    const focusField = () => {
      if (errorCreatAd_Five.minAmount && minAmountRef?.current) {
        minAmountRef.current.focus()
      } else if (errorCreatAd_Five.maxAmount && maxAmountRef?.current) {
        maxAmountRef.current.focus()
      } else if (errorCreatAd_Five.fixedAmount && fixedAmountRef?.current) {
        fixedAmountRef.current.focus()
      } else if (
        errorCreatAd_Five.reccurrency &&
        salaryRecurrenceRef?.current
      ) {
        salaryRecurrenceRef.current.focus()
      } else if (
        errorCreatAd_Five.additional_wages &&
        additionalWagesRef?.current
      ) {
        additionalWagesRef.current.focus()
      } else if (errorCreatAd_Five.benefit && benefitRef?.current) {
        benefitRef.current.focus()
      }
    }

    if (error) {
      focusField()
      setError(false)
    }
  }, [
    error,
    errorCreatAd_Five,
    minAmountRef,
    maxAmountRef,
    fixedAmountRef,
    salaryRecurrenceRef,
    additionalWagesRef,
    benefitRef
  ])

  function handleProdottoChange(e) {
    console.log(e);
    
  }

  console.log('CreatAd_Five', CreatAd_Five)

  return (
    <>
      <Header />
      <div className='seekng_job_sec create-an-ad'>
        <div className='container'>
          <form>
            <div className='seekng_job_title_note'>
              <div className='seekng_job_title'>
                <h2>
                  {setStepParam != null
                    ? "Modifica l'annuncio"
                    : 'Inserisci un nuovo annuncio per la ricerca di agenti'}
                </h2>
                <h6>Informazioni sul prodotto</h6>
              </div>
            </div>
            <div className='seeking_job_inner'>
              <div className='seeking_job_form'>
                <div className='seeking_form_left'>
                  <div className='row g-3'>
                    <div className='col-12'>
                      <label className='form-label d-none d-lg-block'>
                        Settore
                      </label>
                      <Select
                        className='form-control'
                        name='product'
                        options={
                          sectorList?.data?.Message.filter((sector, index, array) => {
                            const sectorIndex = array.findIndex((b) => sector.sectorName === b.sectorName);
                            return index === sectorIndex;
                          }).map(i => ({
                            value: i.id,
                            label: i.sectorName
                          })) || []
                        }
                        value={
                          selectedOptionsSector.subSubmenu
                            ? {
                                label: selectedOptionsSector.subSubmenu,
                                value: selectedOptionsSector.subSubmenu
                              }
                            : null
                        }
                        //isClearable={isClearable}
                        //isSearchable={isSearchable}
                        onChange={handleSectorChange}
                     
                        //filterOption={createFilter(filterConfig)}
                        placeholder='Seleziona il prodotto'
                      />
                    </div>
                  </div>
                </div>
                <div className='seeking_form_right d-none d-lg-block'>
                  <div className='row g-3'></div>
                </div>
                {/*<div className='seeking_form_left mt-4'>
                  <div className='row g-3'>
                    <div className='col-12'>
                      <label className='form-label'>Prodotto</label>
                    </div>
                  </div>
                </div>
                <div className='seeking_form_right mt-4 d-none d-lg-block'>
                  <div className='row g-3'></div>
                </div>*/}
              </div>
            </div>
            <div className='seeking_form_btn'>
              <BackButton prevStep={prevStep} />
              <NextButton nextStep={nextStep} />
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default CreaAnnuncioFiveAgent
