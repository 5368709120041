import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';


/*const firebaseConfig = {
  apiKey: "AIzaSyBIAI391q291sJrwVTmL5ucUF8bKJN4_IU",
  authDomain: "wideer-6ed51.firebaseapp.com",
  projectId: "wideer-6ed51",
  storageBucket: "wideer-6ed51.appspot.com",
  messagingSenderId: "1018413898278",
  appId: "1:1018413898278:web:125245079691df53f724e7",
  measurementId: "G-C9P9SJMP1K"
};*/
const firebaseConfig = {
  apiKey: "AIzaSyBoj6MowfrtJ_iI5p2LKPc8DdxpDm9XoeU",
  authDomain: "wideer-login.firebaseapp.com",
  projectId: "wideer-login",
  storageBucket: "wideer-login.firebasestorage.app",
  messagingSenderId: "299580891815",
  appId: "1:299580891815:web:76d8263028a714dae67182",
  measurementId: "G-G0P7D0QGX0"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);