// File: /src/Components/ProfessionalProfile.js
// Created Date: Friday December 13th 2024
// Author: Christian Nonis <alch.infoemail@gmail.com>
// -----
// Last Modified: Friday December 13th 2024 10:37:11 am
// Modified By: the developer formerly known as Christian Nonis at <alch.infoemail@gmail.com>
// -----

import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import Breadcrumbs from "../Components/Breadcrumbs";
import Footer from "../Components/Footer";
import FormBuilder from "../Components/FormBuilder";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  requestCompanyDetails,
  requestCompanyJWT,
  requestCompanyReference,
  requestCompanyUpdate,
  requestGetCandidate,
  requestGetList,
  userLogout,
} from "../Redux/actions";

const FIELDS = [
  {
    label: "Il tuo nome",
    name: "name",
    type: "text",
    mandatory: true,
  },
  {
    label: "La tua email",
    name: "e_mail",
    type: "email",
    mandatory: true,
  },
  {
    label: "Il tuo numero di telefono",
    name: "cellphone",
    type: "phone",
    mandatory: true,
  },
  {
    label: "La tua nazione",
    name: "country",
    type: "select",
    mandatory: true,
  },
  {
    label: "Il tuo indirizzo",
    name: "street_number",
    type: "text",
    mandatory: true,
  },
  {
    label: "Il tuo comune e la tua provincia",
    name: "city",
    type: "select",
    mandatory: true,
  },
  {
    label: "Il tuo codice postale",
    name: "zipcode",
    type: "text",
    mandatory: true,
  },
];

const ProfessionalProfile = (props) => {
  const [options, setOptions] = useState({
    country: [],
    city: [],
  });

  useEffect(() => {
    props.requestGetList();
    if (!props.company.getCompanyData) {
      props.requestCompanyDetails({
        data: {
          token: props.candidate?.loginData?.data.Message.access,
        },
      });
    }
  }, []);

  useEffect(() => {
    let levelData = props.candidate.getlistData;
    if (levelData !== undefined) {
      if (levelData?.data?.Status === "Success") {
        const processedCountries = levelData.data.Message.countries.map(
          (item) => {
            const name = item.nativeCountryName;
            return name;
          }
        );
        const uniqueCountries = [...new Set(processedCountries)];
        const uniqueCountryData = uniqueCountries
          .map((name) => ({
            value: name,
            label: name,
          }))
          .sort((a, b) => {
            if (a.nativeCountryName === "Italia") return -1;
            if (b.nativeCountryName === "Italia") return 1;
            return 0;
          });

        const municipalit = levelData?.data?.Message?.municipalities;
        const uniqueMunicipalities = [];
        const municipalityNames = new Set();

        municipalit.forEach((municipality) => {
          if (!municipalityNames.has(municipality.name)) {
            municipalityNames.add(municipality.name);
            const municipalityObject = {
              name: `${municipality.name}, ${municipality.provincia.name}`,
            };
            uniqueMunicipalities.push(municipalityObject);
          }
        });
        const transformedCityData =
          uniqueMunicipalities?.map((city, index) => ({
            value: index,
            label: city?.name,
          })) || [];

        setOptions({
          country: uniqueCountryData,
          city: transformedCityData,
        });
      }
    }
  }, [props.candidate.getlistData]);

  const companyData = () => {
    let data = props.company.getCompanyData;
    if (!data) data = props.company.comRegisterData;
    if (!data) return;
    if (!data?.data?.Status === "Success") return;
    data = data?.data?.Message;
    return data;
  };

  const handleSubmit = (formData) => {
    const token = localStorage.getItem("token");
    if (token) {
      props.requestCompanyUpdate({
        data: {
          ...companyData(),
          ...formData,
        },
        token: token,
      });
    }
  };

  return (
    <div className="min-vh-100 d-flex flex-column">
      <Header />
      <Breadcrumbs />
      <div className="container mt-4 min-vh-100">
        <div className="h-100">
          <div className="seekng_job_title mb-4">
            <h2>Profilo professionale</h2>
          </div>
          <FormBuilder
            fields={FIELDS}
            onSubmit={handleSubmit}
            submitLabel="Salva"
            selectOptions={options}
            values={companyData()}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return { company: state.company, candidate: state.candidate };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestGetList,
      requestGetCandidate,
      requestCompanyJWT,
      requestCompanyReference,
      requestCompanyDetails,
      requestCompanyUpdate,
      userLogout,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfessionalProfile);
