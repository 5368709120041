import React, { useState, useRef, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { requestGetList, requestCandidateResume, requestGetEducationLevelList, requestGetSectorList, userLogout } from "../../Redux/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from 'react-i18next';
import Swal from "sweetalert2";
import SkillForm from './skill/SkillForm';
import LanguageForm from './language/LanguageForm';
import ExperienceForm from './experience/ExperinceForm';
import EducationForm from './education/EducationForm';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

const StepForm = (props) => {
    const { data } = props
    const [step, setStep] = useState(() => {
        const savedStep = localStorage.getItem('currentStep');
        return savedStep ? Number(savedStep) : 1;
    });
    const [error, setError] = useState(false);
    const [countryData, setCountryData] = useState([]);
    const [municipalities, setMunicipalities] = useState([]);
    const [selectedOptionsLanguage, setSelectedOptionsLanguage] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [errorskill, seterrorSkill] = useState("");
    const [skillData, setSkillData] = useState([]);
    const [modifyskillData, setModifySkillData] = useState([]);
    const [showNavButtons, setShowNavButtons] = useState(true);

    //===============educational level ===========================

    const [educationLevels, setEducationLevels] = useState([]);
    const [subSubmenuOptions, setSubSubmenuOptions] = useState([]);

    //================sectors level =================================

    const [sectorsLevel, setSectorsLevel] = useState([]);
    const [subSubSectorsLevel, setSubSubSectorsLevel] = useState([]);

    const { t } = useTranslation();
    const [ipAddress, setIpAddress] = useState('');

    const [education, setEducation] = useState(() => {
        const savedEducation = localStorage.getItem('education');
        return savedEducation ? JSON.parse(savedEducation) : [
            {
                educationalLevel: "",
                educationalSector: "",
                type: "",
                name: "",
                address: {
                    country: "Italia",
                    province: "",
                    city: "",
                },
                yearFrom: "",
                monthFrom: "",
                yearTo: "",
                monthTo: "",
            },
        ];
    });

    const [selectedOptionsEducation, setSelectedOptionsEducation] = useState(() => {
        const savedEducationLevel = localStorage.getItem('educationalLevel');
        return savedEducationLevel ? JSON.parse(savedEducationLevel) : [
            {
                submenu: "",
                subSubmenu: ""
            },
        ];
    });

    const [erroreducation, setErroreducation] = useState(() => {
        return education.map(() => ({
            educationalLevel: "",
            // subeducationalLevel: "",
            educationalSector: "",
            type: "",
            name: "",
            address: {
                country: "",
                province: "",
                city: "",
            },
            yearFrom: "",
            monthFrom: "",
            yearTo: "",
            monthTo: "",
        }));
    });

    const [experience, setExperience] = useState(() => {
        const savedExperience = localStorage.getItem('experience');
        return savedExperience ? JSON.parse(savedExperience) : [
            {
                sector: "",
                role: "",
                company: "",
                address: {
                    country: "Italia",
                    province: "",
                    city: "",
                },
                yearFrom: "",
                monthFrom: "",
                yearTo: "",
                monthTo: "",
                reccomendationLetter: "",
                description: "",
            },
        ];
    });

    const [selectedOptionsExperience, setSelectedOptionsExperience] = useState(() => {
        const savedExperienceSector = localStorage.getItem('experienceSector');
        return savedExperienceSector ? JSON.parse(savedExperienceSector) : [
            {
                submenu: "",
                subSubmenu: ""
            },
        ];
    });

    const [errorexperience, setErrorexperience] = useState(() => {
        return experience.map(() => ({
            sector: "",
            role: "",
            company: "",
            address: {
                country: "",
                province: "",
                city: "",
            },
            yearFrom: "",
            monthFrom: "",
            yearTo: "",
            monthTo: "",
            reccomendationLetter: "",
            description: "",
        }));
    });

    const [languages, setLanguages] = useState(() => {
        const savedLanguages = localStorage.getItem('languages');
        return savedLanguages ? JSON.parse(savedLanguages) : [
            {
                language: "",
                levelListening: "",
                levelReading: "",
                levelWritten: "",
                levelSpoken: "",
            },
        ];
    });

    const [errorlanguages, setErrorlanguages] = useState(() => {
        return languages.map(() => ({
            language: "",
            levelListening: "",
            levelReading: "",
            levelWritten: "",
            levelSpoken: "",
        }));
    });

    const [selectedSkills, setSelectedSkills] = useState(() => {
        const savedSkills = localStorage.getItem('skills');
        return savedSkills ? JSON.parse(savedSkills) : [];
    });
    const navigate = useNavigate();

    //=====================================Resume File==========================================

    const [resume, setResume] = useState("");
    const [errorResume_upload, setErrorResume_upload] = useState("");
    const resumeUploadRef = useRef(null);

    //=====================================Resume File==========================================

    const focusRefs = {
        education: useRef([]),
        experience: useRef([]),
        languages: useRef([]),
    };

    const skillRef = useRef(null);

    const isClearable = true;
    const isSearchable = true;
    const matchFromStart = true;

    const mystyle = {
        color: "#D10000",
        padding: "3px 10px",
        width: "100%",
    };


    useEffect(() => {
        const fetchIpAddress = async () => {
            try {
                const response = await axios.get('https://api.ipify.org?format=json');
                setIpAddress(response.data.ip);
            } catch (error) {
                setError('Error fetching IP address');
            }
        };
        fetchIpAddress();
    }, []);

    useEffect(() => {
        props.requestGetList();
        props.requestGetEducationLevelList();
        props.requestGetSectorList();
    }, []);

    // useEffect(() => {
    //     props.requestGetList();
    //     localStorage.setItem('education', JSON.stringify(education));
    //     localStorage.setItem('experience', JSON.stringify(experience));
    //     localStorage.setItem('languages', JSON.stringify(languages));
    //     localStorage.setItem('skills', JSON.stringify(selectedSkills));
    // }, [education, experience, languages, selectedSkills]);


    useEffect(() => {
        let levelData = props.candidate.getlistData;
        if (levelData !== undefined) {
            if (levelData?.data?.Status === "Success") {

                const processedCountries = levelData.data.Message.countries.map(item => {
                    const name = item.nativeCountryName;
                    return name;
                });
                const uniqueCountries = [...new Set(processedCountries)];
                const uniqueCountryData = uniqueCountries.map(name => ({
                    nativeCountryName: name
                }));

                setCountryData(uniqueCountryData);

                const municipalit = levelData?.data?.Message?.municipalities;
                const uniqueMunicipalities = [];
                const municipalityNames = new Set();

                municipalit.forEach(municipality => {
                    if (!municipalityNames.has(municipality.name)) {
                        municipalityNames.add(municipality.name);
                        const municipalityObject = {
                            name: `${municipality.name}, ${municipality.provincia.name}`
                        };
                        uniqueMunicipalities.push(municipalityObject);
                    }
                });

                const transformedCityData = uniqueMunicipalities?.map((city, index) => ({
                    value: index,
                    label: city?.name
                })) || [];

                setMunicipalities(transformedCityData);

                const transformedSkillData = levelData?.data?.Message?.skills?.map((skill, index) => ({
                    value: index,
                    label: skill.skillName
                })) || [];

                setSkillData(transformedSkillData);
            }
        }
    }, [props.candidate.getlistData]);

    const sortedCountryData = [...countryData].sort((a, b) => {
        if (a.nativeCountryName === "Italia") return -1;
        if (b.nativeCountryName === "Italia") return 1;
        return 0;
    });

    //=================================educational level ==================================
    useEffect(() => {
        const educationlevelData = props.candidate.EducationlevelData;
        if (educationlevelData?.data?.Status === "Success") {
            const uniqueLevels = {};

            educationlevelData.data.Message.forEach(level => {
                if (!uniqueLevels[level.levelType]) {
                    uniqueLevels[level.levelType] = { value: level.id, label: level.levelType };
                }
            });
            const transformedEducationlevelData = Object.values(uniqueLevels);
            setEducationLevels(transformedEducationlevelData);

            const filteredSubMenuOptions = educationlevelData.data.Message
                .filter(level => level.specialisation)
                .map(level => ({ levelType: level.levelType, specialisation: level.specialisation }));

            setSubSubmenuOptions(filteredSubMenuOptions);
        }
    }, [props.candidate.EducationlevelData]);

    //====================== sector level =============================================== 

    useEffect(() => {
        const sectorsLevelData = props.candidate.SectorData;
        if (sectorsLevelData?.data?.Status === "Success") {
            const uniqueSectors = {};

            sectorsLevelData.data.Message.forEach(sector => {
                if (!uniqueSectors[sector.sectorName]) {
                    uniqueSectors[sector.sectorName] = { value: sector.id, label: sector.sectorName };
                }
            });
            const transformedSectorlevelData = Object.values(uniqueSectors);
            setSectorsLevel(transformedSectorlevelData);

            const filteredSubMenuOptions = sectorsLevelData.data.Message
                .filter(level => level.subSector)
                .map(level => ({ sectorName: level.sectorName, subSector: level.subSector }));

            setSubSubSectorsLevel(filteredSubMenuOptions);
        }
    }, [props.candidate.SectorData]);


    const filterConfig = {
        matchFrom: matchFromStart ? ('start') : ('any'),
    };

    // ========================== education fuction =======================================

    // const handleInputEducation = (e, index) => {
    //     const { name, value } = e.target;
    //     const list = [...education];

    //     if (name.includes('address')) {
    //         const [_, addressField] = name.split('.');
    //         list[index].address[addressField] = value;

    //         if (addressField === 'country') {
    //             if (value === 'Italia') {
    //                 list[index].address.city = '';
    //                 list[index].address.province = '';
    //             } else {
    //                 list[index].address.city = '';
    //                 list[index].address.province = '';
    //             }
    //         }
    //     } else {
    //         if (name === "educationalLevel") {
    //             if (value) {
    //                 if (value === "Università" || value === "Master") {
    //                     list[index].type = "academy";
    //                 } else {
    //                     list[index].type = "school";
    //                 }
    //             } else {
    //                 list[index].type = "";
    //             }
    //             setSelectedOptionsEducation({
    //                 submenu: value,
    //                 subSubmenu: ""
    //             });
    //         }
    //         list[index][name] = value;
    //     }
    //     setEducation(list);
    // };


    const handleInputEducation = (e, index) => {
        const { name, value } = e.target;
        const newList = [...education];
        // const selectedOptionsCopy = { ...selectedOptionsEducation };

        if (name.includes('address')) {
            const [, addressField] = name.split('.');
            newList[index].address[addressField] = value;

            if (addressField === 'country') {
                if (value === 'Italia') {
                    newList[index].address.city = '';
                    newList[index].address.province = '';
                } else {
                    newList[index].address.city = '';
                    newList[index].address.province = '';
                }
            }
        } else {
            if (name === "educationalLevel") {
                if (value) {
                    if (value === "Università" || value === "Master") {
                        newList[index].type = "academy";
                    } else {
                        newList[index].type = "school";
                    }
                } else {
                    newList[index].type = "";
                }

                // selectedOptionsCopy[index] = {
                //     submenu: value,
                //     subSubmenu: "",
                // };
            }

            newList[index][name] = value;
        }

        setEducation(newList);
        // setSelectedOptionsEducation(selectedOptionsCopy);
    };

    //=============== single educational level inserted and  select educational level =========================

    const handleChangeEducationLevel = (selectedOption, i) => {
        if (selectedOption !== null) {
            setSelectedOptionsEducation((prevOptions) => ({
                ...prevOptions,
                [i]: {
                    ...prevOptions[i],
                    submenu: selectedOption?.label || "",
                    subSubmenu: ""
                },
            }));

            if (education) {
                const updatedEducation = education.map((edu, index) =>
                    index === i ? { ...edu, educationalLevel: selectedOption?.label } : edu
                );
                setEducation(updatedEducation);
            }
            const list = [...erroreducation];
            list[i].educationalLevel = "";
            setErroreducation(list);
            // validateSubEducationLevel(i)
        } else {
            setSelectedOptionsEducation((prevOptions) => ({
                ...prevOptions,
                [i]: {
                    ...prevOptions[i],
                    submenu: "",
                    subSubmenu: ""
                },
            }));

            if (education) {
                const updatedEducation = education.map((edu, index) =>
                    index === i ? { ...edu, educationalLevel: "" } : edu
                );
                setEducation(updatedEducation);
            }
            validateEducationLevel(i);
        }
    };

    const handleSubsubmenuChange = (e, index) => {
        const selectedValue = e.target.value;

        setSelectedOptionsEducation((prevOptions) => ({
            ...prevOptions,
            [index]: {
                ...prevOptions[index],
                subSubmenu: selectedValue,
            },
        }));

        setEducation((prevEducation) => {
            const updatedEducation = [...prevEducation];
            updatedEducation[index] = {
                ...updatedEducation[index],
                educationalLevel: `${selectedOptionsEducation[index]?.submenu || ""}, ${selectedValue}`
            };
            return updatedEducation;
        });
        // validateSubEducationLevel(index)
    };


    const handleChangeEducationCity = async (e, index) => {
        setIsLoading(true);
        if (e !== null) {
            setTimeout(() => {
                const list = [...education];
                const [city, province] = e?.label.split(', ');
                if (list[index] && list[index].address) {
                    list[index].address.city = city;
                    list[index].address.province = province;
                }
                setEducation(list);
                setIsLoading(false);
                validateEducationCommon(index);
            }, 1000);
        } else {
            validateEducationCommon(index);
            setIsLoading(false);
        }
    };

    // const handleRemoveEducation = (index) => {
    //     const list = [...education];
    //     list.splice(index, 1);
    //     setEducation(list);
    //     const errorlist = [...erroreducation];
    //     errorlist.splice(index, 1);
    //     setErroreducation(errorlist);
    //     if (list.length === 0) {
    //         setShowNavButtons(false);
    //     }
    // };

    const handleRemoveEducation = (index) => {
        const updatedEducation = [...education];
        updatedEducation.splice(index, 1);
        setEducation(updatedEducation);

        const updatedErrorEducation = [...erroreducation];
        updatedErrorEducation.splice(index, 1);
        setErroreducation(updatedErrorEducation);

        const updatedSelectedOptions = { ...selectedOptionsEducation };
        delete updatedSelectedOptions[index];
        setSelectedOptionsEducation(updatedSelectedOptions);

        if (updatedEducation.length === 0) {
            setShowNavButtons(false);
        }
    };


    const handleAddEducation = () => {
        setShowNavButtons(true)
        let length = education.length;
        if (length === 0) {
            setEducation([
                ...education,
                {
                    educationalLevel: "",
                    // educationalSector: "",
                    type: "",
                    name: "",
                    address: {
                        country: "Italia",
                        province: "",
                        city: ""
                    },
                    // grade: "",
                    yearFrom: "",
                    monthFrom: "",
                    yearTo: "",
                    monthTo: "",
                },
            ]);

            setErroreducation([
                ...erroreducation,
                {
                    educationalLevel: "",
                    // educationalSector: "",
                    type: "",
                    name: "",
                    address: {
                        country: "",
                        province: "",
                        city: ""
                    },
                    // grade: "",
                    yearFrom: "",
                    monthFrom: "",
                    yearTo: "",
                    monthTo: "",
                },
            ]);
        } else if (
            education[length - 1].educationalLevel === "" ||
            // education[length - 1].educationalSector === "" ||
            // education[length - 1].type === "" ||
            education[length - 1].name === "" ||
            education[length - 1].address.country === "" ||
            education[length - 1].address.province === "" ||
            education[length - 1].address.city === "" ||
            // education[length - 1].grade === "" ||
            education[length - 1].yearFrom === "" ||
            education[length - 1].yearTo === ""
        ) {
            Swal.fire(
                "Errore!",
                "Dopo aver completato quello precedente, puoi aggiungerne uno nuovo.",
                "error"
            );
            return;
        } else {

            setEducation([
                ...education,
                {
                    educationalLevel: "",
                    // educationalSector: "",
                    type: "",
                    name: "",
                    address: {
                        country: "Italia",
                        province: "",
                        city: ""
                    },
                    // grade: "",
                    yearFrom: "",
                    monthFrom: "",
                    yearTo: "",
                    monthTo: "",
                },
            ]);
            setErroreducation([
                ...erroreducation,
                {
                    educationalLevel: "",
                    // educationalSector: "",
                    type: "",
                    name: "",
                    address: {
                        country: "",
                        province: "",
                        city: ""
                    },
                    // grade: "",
                    yearFrom: "",
                    monthFrom: "",
                    yearTo: "",
                    monthTo: "",
                },
            ]);
        }

        setSelectedOptionsEducation((prevOptions) => ({
            ...prevOptions,
            [length]: {
                submenu: "",
                subSubmenu: ""
            },
        }));
    };

    // ============================ exprience fuction ========================

    const handleInputExperience = (e, index) => {
        const { name, value } = e.target;
        const list = [...experience];
        // const selectedOptionsCopy = { ...selectedOptionsExperience };

        if (name.includes('address')) {
            const [_, addressField] = name.split('.');
            list[index].address[addressField] = value;

            if (addressField === 'country') {
                if (value === 'Italia') {
                    list[index].address.city = '';
                    list[index].address.province = '';
                } else {
                    list[index].address.city = '';
                    list[index].address.province = '';
                }
            }
        } else {
            // if (name === "sector") {
            //     selectedOptionsCopy[index] = {
            //         submenu: value,
            //         subSubmenu: "",
            //     };
            // }
            list[index][name] = value;
        }

        setExperience(list);
        // setSelectedOptionsExperience(selectedOptionsCopy)
    };


    //=============== single educational level inserted and  select educational level =========================

    const handleChangeSectorLevel = (selectedOption, i) => {
        if (selectedOption !== null) {
            setSelectedOptionsExperience((prevOptions) => ({
                ...prevOptions,
                [i]: {
                    ...prevOptions[i],
                    submenu: selectedOption?.label || "",
                    subSubmenu: ""
                },
            }));

            if (education) {
                const updatedExperience = experience.map((edu, index) =>
                    index === i ? { ...edu, sector: selectedOption?.label } : edu
                );
                setExperience(updatedExperience);
            }
            const list = [...errorexperience];
            list[i].sector = "";
            setErrorexperience(list);
        } else {
            setSelectedOptionsExperience((prevOptions) => ({
                ...prevOptions,
                [i]: {
                    ...prevOptions[i],
                    submenu: "",
                    subSubmenu: ""
                },
            }));

            if (education) {
                const updatedExperience = experience.map((exp, index) =>
                    index === i ? { ...exp, sector: "" } : exp
                );
                setExperience(updatedExperience);
            }
            validateExperienceSector(i);
        }
    };

    const handleSubMenuExpChange = (e, index) => {
        const selectedValue = e.target.value;

        setSelectedOptionsExperience((prevOptions) => ({
            ...prevOptions,
            [index]: {
                ...prevOptions[index],
                subSubmenu: selectedValue,
            },
        }));

        setExperience((prevExperience) => {
            const updatedExperience = [...prevExperience];
            updatedExperience[index] = {
                ...updatedExperience[index],
                sector: `${selectedOptionsExperience[index]?.submenu || ""}, ${selectedValue}`
            };
            return updatedExperience;
        });
    };

    // const handleSubMenuExpChange111 = (e, index) => {
    //     const selectedValue = e.target.value;
    //     console.log("selectedValue",selectedValue)
    //     setSelectedOptionsExperience((prevOptions) => ({
    //         ...prevOptions,
    //         subSubmenu: selectedValue,
    //     }));

    //     setExperience((prevExperience) => {
    //         const updatedExperience = [...prevExperience];
    //         updatedExperience[index] = {
    //             ...updatedExperience[index],
    //             sector: `${selectedOptionsExperience[index].submenu || ''}, ${selectedValue}`
    //         };
    //         return updatedExperience;
    //     });
    // };

    // const handleRemoveExperience = (index) => {
    //     const list = [...experience];
    //     list.splice(index, 1);
    //     setExperience(list);
    //     const errorlist = [...errorexperience];
    //     errorlist.splice(index, 1);
    //     setErrorexperience(errorlist);
    //     if (list.length === 0) {
    //         setShowNavButtons(false);
    //     }
    // };

    const handleRemoveExperience = (index) => {
        const updatedExperience = [...experience];
        updatedExperience.splice(index, 1);
        setExperience(updatedExperience);

        const updatedErrorExperience = [...errorexperience];
        updatedErrorExperience.splice(index, 1);
        setErrorexperience(updatedErrorExperience);

        const updatedSelectedOptions = { ...selectedOptionsExperience };
        delete updatedSelectedOptions[index];
        setSelectedOptionsExperience(updatedSelectedOptions);

        if (updatedExperience.length === 0) {
            setShowNavButtons(false);
        }
    };

    const handleAddExperience = () => {
        setShowNavButtons(true)

        let length = experience.length;

        if (length === 0) {
            setExperience([
                ...experience,
                {
                    sector: "",
                    role: "",
                    company: "",
                    address: {
                        country: "Italia",
                        province: "",
                        city: "",
                    },
                    yearFrom: "",
                    monthFrom: "",
                    yearTo: "",
                    monthTo: "",
                    reccomendationLetter: "",
                    description: "",
                },
            ]);
            setErrorexperience([
                ...errorexperience,
                {
                    sector: "",
                    role: "",
                    company: "",
                    address: {
                        country: "",
                        province: "",
                        city: ""
                    },
                    yearFrom: "",
                    monthFrom: "",
                    yearTo: "",
                    monthTo: "",
                    reccomendationLetter: "",
                    description: ""
                },
            ]);
        } else if (
            experience[length - 1].sector === "" ||
            experience[length - 1].role === "" ||
            experience[length - 1].company === "" ||
            experience[length - 1].address.country === "" ||
            experience[length - 1].address.province === "" ||
            experience[length - 1].address.city === "" ||
            experience[length - 1].yearFrom === "" ||
            experience[length - 1].yearTo === ""
            // experience[length - 1].description === ""
        ) {
            Swal.fire(
                "Errore!",
                "Dopo aver completato quello precedente, puoi aggiungerne uno nuovo.",
                "error"
            );
            return;
        } else {
            setExperience([
                ...experience,
                {
                    sector: "",
                    role: "",
                    company: "",
                    address: {
                        country: "Italia",
                        province: "",
                        city: ""
                    },
                    yearFrom: "",
                    monthFrom: "",
                    yearTo: "",
                    monthTo: "",
                    reccomendationLetter: "",
                    description: "",
                },
            ]);
            setErrorexperience([
                ...errorexperience,
                {
                    sector: "",
                    role: "",
                    company: "",
                    address: {
                        country: "",
                        province: "",
                        city: ""
                    },
                    yearFrom: "",
                    monthFrom: "",
                    yearTo: "",
                    monthTo: "",
                    reccomendationLetter: "",
                    description: "",
                },
            ]);
        }

        setSelectedOptionsExperience((prevOptions) => ({
            ...prevOptions,
            [length]: {
                submenu: "",
                subSubmenu: ""
            },
        }));
    };

    const handleChangeExprienceCity = async (e, index) => {
        setIsLoading(true);
        if (e !== null) {
            setTimeout(() => {
                const list = [...experience];
                const [city, province] = e?.label.split(', ');
                if (list[index] && list[index].address) {
                    list[index].address.city = city;
                    list[index].address.province = province;
                }
                setExperience(list);
                setIsLoading(false);
                validateExperienceCommon(index);
            }, 1000);
        } else {
            setIsLoading(false);
        }
    };

    const handleInputExperience1 = (e, index) => {
        const { name } = e.target;
        const value = e.target.files[0];
        const list = [...experience];
        list[index][name] = value;
        setExperience(list);
    };

    // ============================ language fuction ========================

    const handleInputLanguage = (e, index) => {
        const { name, value } = e.target;
        const list = [...languages];
        list[index][name] = value;
        setLanguages(list);
    };

    const handleChangeLanguage = (selectedOption, i) => {
        const updatedSelectedOptions = [...selectedOptionsLanguage];
        updatedSelectedOptions[i] = selectedOption || null;

        setSelectedOptionsLanguage(updatedSelectedOptions);

        if (languages) {
            const updatedLanguages = languages.map((lang, index) =>
                index === i ? { ...lang, language: selectedOption?.label } : lang
            );
            setLanguages(updatedLanguages);
        }
    };

    const handleRemoveLanguage = (index) => {
        const list = [...languages];
        list.splice(index, 1);
        setLanguages(list);
        const errorlist = [...errorlanguages];
        errorlist.splice(index, 1);
        setErrorlanguages(errorlist);
        if (list.length === 0) {
            setShowNavButtons(false);
        }
    };

    const handleAddLanguages = () => {
        setShowNavButtons(true)

        let length = languages.length;
        if (length === 0) {
            setLanguages([
                ...languages,
                {
                    language: "",
                    levelListening: "",
                    levelReading: "",
                    levelWritten: "",
                    levelSpoken: "",
                },
            ]);
            setErrorlanguages([
                ...errorlanguages,
                {
                    language: "",
                    levelListening: "",
                    levelReading: "",
                    levelWritten: "",
                    levelSpoken: "",
                },
            ]);
        } else if (
            languages[length - 1].language === "" ||
            languages[length - 1].levelListening === "" ||
            languages[length - 1].levelReading === "" ||
            languages[length - 1].levelWritten === "" ||
            languages[length - 1].levelSpoken === ""
        ) {
            Swal.fire(
                "Errore!",
                "Dopo aver completato quello precedente, puoi aggiungerne uno nuovo.",
                "error"
            );
            return;
        } else {
            setLanguages([
                ...languages,
                {
                    language: "",
                    levelListening: "",
                    levelReading: "",
                    levelWritten: "",
                    levelSpoken: "",
                },
            ]);
            setErrorlanguages([
                ...errorlanguages,
                {
                    language: "",
                    levelListening: "",
                    levelReading: "",
                    levelWritten: "",
                    levelSpoken: "",
                },
            ]);
        }
    };

    //============== skill function ====================

    const handleChangeSkill = (selectedSkills) => {
        setSelectedSkills(selectedSkills);
        const skillTypes = selectedSkills ? selectedSkills.map(option => ({ skillName: option.label })) : [];
        setModifySkillData(skillTypes);
        seterrorSkill('');
    };

    const handleCreateSkillOption = (inputValue) => {
        const newIndex = skillData?.length;
        const newOption = { value: newIndex, label: inputValue };
        setSkillData(prevData => {
            const updatedData = [...prevData, newOption];
            return updatedData;
        });
        setSelectedSkills(prevData => {
            const updatedData = [...prevData, newOption];
            const skillTypes = updatedData?.map(option => ({ skillName: option.label }));
            setModifySkillData(skillTypes);
            return updatedData;
        });
        seterrorSkill('');
    };

    //======================== education validation ======================================

    function validateEducationLevel(i) {
        let formIsValid = false;
        const trimmedEducationalLevel = education[i].educationalLevel;

        if (!trimmedEducationalLevel) {
            formIsValid = false;
            const list = [...erroreducation];
            list[i].educationalLevel = "Seleziona il nome della tua Livello istruzione.";
            setErroreducation(list);
        } else {
            formIsValid = true;
            const list = [...erroreducation];
            list[i].educationalLevel = "";
            setErroreducation(list);
        }
        return formIsValid;
    }

 //======================== sub  education validation ======================================

    // function validateSubEducationLevel(i) {
    //     let formIsValid = true; 
    
    //     if (focusRefs.education.current[i]) {
    //         const subEducation = focusRefs.education.current[i].subeducationalLevel?.value;
    
    //         const list = [...erroreducation];

    //         if (!subEducation) {
    //             formIsValid = false;
    //             list[i].subeducationalLevel = "Seleziona il tuo Indirizzo / Specializzazione.";
    //         } else {
    //             list[i].subeducationalLevel = "";
    //         }
    //         setErroreducation(list);
    //     }
    
    //     return formIsValid;
    // }


    // function validateEducationStudysector(i) {
    //     let formIsValid = true;
    //     const trimmedStudysector = education[i]?.educationalSector;

    //     if (!trimmedStudysector || !/^[A-Za-z.\s]+$/.test(trimmedStudysector)) {
    //         formIsValid = false;
    //         const list = [...erroreducation];
    //         list[i].educationalSector = "Inserisci il nome del tuo settore di studio.";
    //         setErroreducation(list);
    //     } else {
    //         const list = [...erroreducation];
    //         list[i].educationalSector = "";
    //         setErroreducation(list);
    //     }

    //     return formIsValid;
    // }

    function validateEducationInstituteName(i) {
        let formIsValid = true;
        const trimmedInstituteName = education[i]?.name;

        if (!trimmedInstituteName || !/^[A-Za-z.\s]+$/.test(trimmedInstituteName)) {
            formIsValid = false;
            const list = [...erroreducation];
            list[i].name = "Inserisci il nome del tuo istituto (solo caratteri alfabetici e .).";
            setErroreducation(list);
        } else {
            const list = [...erroreducation];
            list[i].name = "";
            setErroreducation(list);
        }

        return formIsValid;
    }

    function validateEducationCountry(i) {
        let formIsValid = false;
        const trimmedCountryEducation = education[i].address.country;

        if (!trimmedCountryEducation) {
            formIsValid = false;
            const list = [...erroreducation];
            list[i].address.country = "Seleziona la tua country.";
            setErroreducation(list);
        } else {
            formIsValid = true;
            const list = [...erroreducation];
            list[i].address.country = "";
            setErroreducation(list);
        }
        return formIsValid;
    }

    function validateEducationProvince(i) {
        let formIsValid = false;
        const trimmedProvinceEducation = education[i]?.address?.province;

        if (!trimmedProvinceEducation) {
            formIsValid = false;
            const list = [...erroreducation];
            list[i].address.province = "Inserisci la tua provincia.";
            setErroreducation(list);
        } else {
            formIsValid = true;
            const list = [...erroreducation];
            list[i].address.province = "";
            setErroreducation(list);
        }
        return formIsValid;
    }

    function validateEducationCommon(i) {
        let formIsValid = true;
        if (education[i]?.address?.country === 'Italia') {
            if (!education[i]?.address?.city) {
                formIsValid = false;
                const list = [...erroreducation];
                list[i].address.city = "Seleziona il tuo Comune.";
                setErroreducation(list);
            }
        } else {
            if (!education[i]?.address?.city) {
                formIsValid = false;
                const list = [...erroreducation];
                list[i].address.city = "Inserisci il nome del tuo Comune.";
                setErroreducation(list);
            }
        }
        if (formIsValid) {
            const list = [...erroreducation];
            list[i].address.city = "";
            setErroreducation(list);
        }

        return formIsValid;
    }

    //============================= exprience  validation ====================================

    function validateExperienceSector(i) {
        let formIsValid = false;
        const trimmedSectorExprience = experience[i]?.sector;
        if (!trimmedSectorExprience) {
            formIsValid = false;
            const list = [...errorexperience];
            list[i].sector = "Seleziona il nome facoltativo settore";
            setErrorexperience(list);
        } else {
            formIsValid = true;
            const list = [...errorexperience];
            list[i].sector = "";
            setErrorexperience(list);
        }
        return formIsValid;
    }

    function validateExperienceRole(i) {
        let formIsValid = true;
        const trimmedRole = (experience[i]?.role || "").trim();
        if (!trimmedRole || !/^[A-Za-z\s]+$/.test(trimmedRole)) {
            formIsValid = false;
            const updatedErrorExperience = [...errorexperience];
            updatedErrorExperience[i].role = "Inserisci il tuo ruolo (solo caratteri alfabetici).";
            setErrorexperience(updatedErrorExperience);
        } else {
            const updatedErrorExperience = [...errorexperience];
            updatedErrorExperience[i].role = "";
            setErrorexperience(updatedErrorExperience);
        }

        return formIsValid;
    }

    function validateExperienceCompany(i) {
        let formIsValid = true;
        const trimmedCompany = (experience[i]?.company || "").trim();

        if (!trimmedCompany) {
            formIsValid = false;
            const updatedErrorExperience = [...errorexperience];
            updatedErrorExperience[i].company = "Inserisci il nome dell'azienda.";
            setErrorexperience(updatedErrorExperience);
        } else {
            const updatedErrorExperience = [...errorexperience];
            updatedErrorExperience[i].company = "";
            setErrorexperience(updatedErrorExperience);
        }
        return formIsValid;
    }

    // function validateExperienceDescription(i) {
    //     let formIsValid = true;
    //     const trimmedDescription = (experience[i]?.description || "").trim();

    //     if (!trimmedDescription) {
    //         formIsValid = false;
    //         const updatedErrorExperience = [...errorexperience];
    //         updatedErrorExperience[i].description = "Inserisci la descrizione del lavoro.";
    //         setErrorexperience(updatedErrorExperience);
    //     } else {
    //         const updatedErrorExperience = [...errorexperience];
    //         updatedErrorExperience[i].description = "";
    //         setErrorexperience(updatedErrorExperience);
    //     }
    //     return formIsValid;
    // }

    function validateExperienceCountry(i) {
        let formIsValid = false;
        const trimmedCountryExprience = experience[i].address.country;

        if (!trimmedCountryExprience) {
            formIsValid = false;
            const list = [...errorexperience];
            list[i].address.country = "Seleziona la tua country.";
            setErrorexperience(list);
        } else {
            formIsValid = true;
            const list = [...errorexperience];
            list[i].address.country = "";
            setErrorexperience(list);
        }
        return formIsValid;
    }

    function validateExperienceProvince(i) {
        let formIsValid = false;
        const trimmedProvinceExprience = experience[i]?.address?.province;

        if (!trimmedProvinceExprience) {
            formIsValid = false;
            const list = [...errorexperience];
            list[i].address.province = "Inserisci la tua provincia.";
            setErrorexperience(list);
        } else {
            formIsValid = true;
            const list = [...errorexperience];
            list[i].address.province = "";
            setErrorexperience(list);
        }
        return formIsValid;
    }

    function validateExperienceCommon(i) {
        let formIsValid = true;
        if (experience[i]?.address?.country === 'Italia') {
            if (!experience[i]?.address?.city) {
                formIsValid = false;
                const list = [...errorexperience];
                list[i].address.city = "Seleziona il tuo Comune.";
                setErrorexperience(list);
            }
        } else {
            if (!experience[i]?.address?.city) {
                formIsValid = false;
                const list = [...errorexperience];
                list[i].address.city = "Inserisci il nome del tuo Comune.";
                setErrorexperience(list);
            }
        }
        if (formIsValid) {
            const list = [...errorexperience];
            list[i].address.city = "";
            setErrorexperience(list);
        }

        return formIsValid;
    }

    function validateExperienceYearFrom(i) {
        let formIsValid = true;
        const today = new Date().getFullYear();

        if (!experience[i].yearFrom) {
            formIsValid = false;
            const updatedErrors = [...errorexperience];
            updatedErrors[i].yearFrom = "Seleziona l'anno di inizio.";
            setErrorexperience(updatedErrors);
        }
        // else if (experience[i].yearFrom >= today) {
        //     formIsValid = false;
        //     const updatedErrors = [...errorexperience];
        //     updatedErrors[i].yearFrom = "L'anno di inizio dovrebbe essere nel passato.";
        //     setErrorexperience(updatedErrors);
        // } 
        else {
            const updatedErrors = [...errorexperience];
            updatedErrors[i].yearFrom = "";
            setErrorexperience(updatedErrors);
        }

        return formIsValid;
    }

    function validateExperienceYearTo(i) {
        let formIsValid = true;
        if (!experience[i].yearTo) {
            formIsValid = false;
            const updatedErrors = [...errorexperience];
            updatedErrors[i].yearTo = "Seleziona l'anno di fine.";
            setErrorexperience(updatedErrors);
        } else if (experience[i].yearTo < experience[i].yearFrom) {
            formIsValid = false;
            const updatedErrors = [...errorexperience];
            updatedErrors[i].yearTo = "L'anno di fine deve essere maggiore dell'anno di inizio.";
            setErrorexperience(updatedErrors);
        } else {
            const updatedErrors = [...errorexperience];
            updatedErrors[i].yearTo = "";
            setErrorexperience(updatedErrors);
        }

        return formIsValid;
    }

    const validateExperienceLetter = (i) => {
        const file1 = experience[i]?.reccomendationLetter;

        if (file1) {
            const allowedFormats = ['.pdf'];
            const fileExtension = file1.name.substring(file1.name.lastIndexOf('.')).toLowerCase();
            if (!allowedFormats.includes(fileExtension)) {
                const list = [...errorexperience];
                list[i] = { ...list[i], reccomendationLetter: "Formato file non valido. Si prega di caricare un file PDF." };
                setErrorexperience(list);
                return false;
            }
        }

        const list = [...errorexperience];
        list[i] = { ...list[i], reccomendationLetter: "" };
        setErrorexperience(list);
        return true;
    };

    //==================== languages validation =======================================

    function validateEducationYearFrom(i) {
        let formIsValid = true;
        const today = new Date().getFullYear();

        if (!education[i].yearFrom) {
            formIsValid = false;
            const updatedErrors = [...erroreducation];
            updatedErrors[i].yearFrom = "Seleziona l'anno di inizio.";
            setErroreducation(updatedErrors);
        } else if (education[i].yearFrom >= today) {
            formIsValid = false;
            const updatedErrors = [...erroreducation];
            updatedErrors[i].yearFrom = "L'anno di inizio dovrebbe essere nel passato.";
            setErroreducation(updatedErrors);
        } else {
            const updatedErrors = [...erroreducation];
            updatedErrors[i].yearFrom = "";
            setErroreducation(updatedErrors);
        }

        return formIsValid;
    }

    function validateEducationYearTo(i) {
        let formIsValid = true;

        if (!education[i].yearTo) {
            formIsValid = false;
            const updatedErrors = [...erroreducation];
            updatedErrors[i].yearTo = "Seleziona l'anno di fine.";
            setErroreducation(updatedErrors);
        } else if (education[i].yearTo < education[i].yearFrom) {
            formIsValid = false;
            const updatedErrors = [...erroreducation];
            updatedErrors[i].yearTo = "L'anno finale deve essere maggiore dell'anno iniziale.";
            setErroreducation(updatedErrors);
        } else {
            const updatedErrors = [...erroreducation];
            updatedErrors[i].yearTo = "";
            setErroreducation(updatedErrors);
        }
        return formIsValid;
    }

    function validateLevelListening(i) {
        let formIsValid = true;
        const languageFilled = focusRefs?.languages?.current[i]?.language?.props?.value;

        if (languageFilled && (!languages[i]?.levelListening || typeof languages[i]?.levelListening === "undefined")) {
            formIsValid = false;
            const updatedErrorLanguages = [...errorlanguages];
            updatedErrorLanguages[i].levelListening = "Seleziona il livello di ascolto.";
            setErrorlanguages(updatedErrorLanguages);
        } else {
            const updatedErrorLanguages = [...errorlanguages];
            updatedErrorLanguages[i].levelListening = "";
            setErrorlanguages(updatedErrorLanguages);
        }

        return formIsValid;
    }

    function validateLevelReading(i) {
        let formIsValid = true;
        const languageFilled = focusRefs?.languages?.current[i]?.language?.props?.value;

        if (languageFilled && (!languages[i]?.levelReading || typeof languages[i]?.levelReading === "undefined")) {
            formIsValid = false;
            const updatedErrorLanguages = [...errorlanguages];
            updatedErrorLanguages[i].levelReading = "Seleziona il livello di lettura.";
            setErrorlanguages(updatedErrorLanguages);
        } else {
            const updatedErrorLanguages = [...errorlanguages];
            updatedErrorLanguages[i].levelReading = "";
            setErrorlanguages(updatedErrorLanguages);
        }

        return formIsValid;
    }

    function validateLevelWritten(i) {
        let formIsValid = true;
        const languageFilled = focusRefs?.languages?.current[i]?.language?.props?.value;

        if (languageFilled && (!languages[i]?.levelWritten || typeof languages[i]?.levelWritten === "undefined")) {
            formIsValid = false;
            const updatedErrorLanguages = [...errorlanguages];
            updatedErrorLanguages[i].levelWritten = "Seleziona il livello dello scritto.";
            setErrorlanguages(updatedErrorLanguages);
        } else {
            const updatedErrorLanguages = [...errorlanguages];
            updatedErrorLanguages[i].levelWritten = "";
            setErrorlanguages(updatedErrorLanguages);
        }

        return formIsValid;
    }

    function validateLevelSpoken(i) {
        let formIsValid = true;
        const languageFilled = focusRefs?.languages?.current[i]?.language?.props?.value;

        if (languageFilled && (!languages[i]?.levelSpoken || typeof languages[i]?.levelSpoken === "undefined")) {
            formIsValid = false;
            const updatedErrorLanguages = [...errorlanguages];
            updatedErrorLanguages[i].levelSpoken = "Seleziona il livello del parlato.";
            setErrorlanguages(updatedErrorLanguages);
        } else {
            const updatedErrorLanguages = [...errorlanguages];
            updatedErrorLanguages[i].levelSpoken = "";
            setErrorlanguages(updatedErrorLanguages);
        }

        return formIsValid;
    }

    // ======================= skills validation =========================================

    const validateSkill = () => {
        if (selectedSkills?.length === 0) {
            seterrorSkill('Seleziona almeno una skill.');
            return false;
        } else {
            seterrorSkill('');
            return true;
        }
    }

    //=====================================Resume File==========================================


    const handleChangeResume = (e) => {
        const { type, files } = e.target;
        let newValue;

        if (type === 'file') {
            newValue = files[0];
            setResume(newValue)
        }
        else {
            setResume("");
        }
    }

    const validateResume_upload = () => {
        const selectedFile = resumeUploadRef.current.files[0];
        if (selectedFile) {
            const allowedFormats = ['.pdf', '.doc', '.docx'];
            const fileExtension = selectedFile.name.substring(selectedFile.name.lastIndexOf('.')).toLowerCase();

            if (!allowedFormats.includes(fileExtension)) {
                setErrorResume_upload('Formato file non valido. Si prega di caricare un file PDF, DOC o DOCX.');
                return false;
            }
        }
        setErrorResume_upload('');
        return true;
    };


    //=====================================Resume File==========================================

    const validateEducationForm = () => {
        return education.every((_, i) => {
            return [
                validateEducationLevel(i),
                // validateSubEducationLevel(i),
                validateEducationInstituteName(i),
                validateEducationCountry(i),
                validateEducationProvince(i),
                validateEducationCommon(i),
                validateEducationYearFrom(i),
                validateEducationYearTo(i)
            ].every(validation => validation);
        });
    };

    const validateExperienceForm = () => {
        return experience.every((_, i) => {
            return [
                validateExperienceSector(i),
                validateExperienceRole(i),
                validateExperienceCountry(i),
                validateExperienceCommon(i),
                validateExperienceProvince(i),
                validateExperienceCompany(i),
                validateExperienceYearFrom(i),
                validateExperienceYearTo(i),
                // validateExperienceDescription(i)
            ].every(validation => validation);
        });
    };

    const validateLanguagesForm = () => {
        return languages.every((_, i) => {
            return [
                validateLevelListening(i),
                validateLevelReading(i),
                validateLevelWritten(i),
                validateLevelSpoken(i)
            ].every(validation => validation);
        });
    };

    /*useEffect(() => {
        languages.forEach((lang, i) => {
            if (lang.language) {
                validateLevelListening(i);
                validateLevelReading(i);
                validateLevelWritten(i);
                validateLevelSpoken(i);
            }
        });
    }, [languages]);*/

  

    const handleNextStep = (formType) => {
        let isValid = false;
        const token = localStorage.getItem('token');
    
        switch (formType) {
            case 'education':
                isValid = validateEducationForm();
                if (isValid) {
                    localStorage.setItem('education', JSON.stringify(education));
                    localStorage.setItem('educationalLevel', JSON.stringify(selectedOptionsEducation));
                    props.requestCandidateResume({
                        data: {
                            userIp: ipAddress,
                            username: data.username,
                            educations: education,
                        },
                        token: token
                    });
                }
                break;
            case 'experience':
                isValid = validateExperienceForm();
                if (isValid) {
                    localStorage.setItem('experience', JSON.stringify(experience));
                    localStorage.setItem('experienceSector', JSON.stringify(selectedOptionsExperience));
                    props.requestCandidateResume({
                        data: {
                            userIp: ipAddress,
                            username: data.username,
                            works: experience,
                        },
                        token: token
                    });
                }
                break;
            case 'languages':
                isValid = validateLanguagesForm();
                if (isValid) {
                    localStorage.setItem('languages', JSON.stringify(languages));
                    props.requestCandidateResume({
                        data: {
                            userIp: ipAddress,
                            username: data.username,
                            languages: languages,
                            sendEmail: true
                        },
                        token: token
                    });
                }
                break;
            default:
                break;
        }
    
        if (isValid) {
            const nextStep = step + 1;
            
            try {
                const addresume = props.candidate.resumeData;
                console.log("addresume", props);
                if (addresume?.data?.Status === "Success" || props.candidate.EducationlevelData.data.Status === "Success") {
                    toast.success(addresume?.data?.Message, { theme: "colored", autoClose: 8000 });
                    setStep(nextStep);
                    localStorage.setItem('currentStep', nextStep);
                } else {
                    toast.error(addresume?.data?.Message, { theme: "colored", autoClose: 8000 });
                }
    
                //props.candidate.resumeData = undefined;
                setError(false);
                window.scrollTo({ top: 0, behavior: 'smooth' });
    
            } catch (error) {
                console.error('Error occurred while calling API:', error);
                setError(true);
            }
        } else {
            setError(true);
            
        }
    };

    // const handleNextStep = async (formType) => {
    //     let isValid = false;
    
    //     switch (formType) {
    //         case 'education':
    //             isValid = validateEducationForm();
    //             break;
    //         case 'experience':
    //             isValid = validateExperienceForm();
    //             break;
    //         case 'languages':
    //             isValid = validateLanguagesForm();
    //             break;
    //         default:
    //             break;
    //     }
    
    //     if (isValid) {
    //         try {
    //             let requestData;
    //             switch (formType) {
    //                 case 'education':
    //                     requestData = { educations: education };
    //                     break;
    //                 case 'experience':
    //                     requestData = { works: experience };
    //                     break;
    //                 case 'languages':
    //                     requestData = { languages: languages };
    //                     break;
    //                 default:
    //                     return;
    //             }
    
    //             await props.requestCandidateResume({
    //                 data: {
    //                     userIp: ipAddress,
    //                     username: data.username,
    //                     ...requestData,
    //                 },
    //             });
    
    //             const addresume = props.candidate.resumeData;
    
    //             if (addresume?.data?.Status === "Success") {
    //                 const nextStep = step + 1;
    //                 switch (formType) {
    //                     case 'education':
    //                         localStorage.setItem('education', JSON.stringify(education));
    //                         localStorage.setItem('educationalLevel', JSON.stringify(selectedOptionsEducation));
    //                         break;
    //                     case 'experience':
    //                         localStorage.setItem('experience', JSON.stringify(experience));
    //                         localStorage.setItem('experienceSector', JSON.stringify(selectedOptionsExperience));
    //                         break;
    //                     case 'languages':
    //                         localStorage.setItem('languages', JSON.stringify(languages));
    //                         break;
    //                     default:
    //                         break;
    //                 }
    
    //                 toast.success(addresume?.data?.Message, { theme: "colored", autoClose: 8000 });
    //                 setStep(nextStep);
    //                 localStorage.setItem('currentStep', nextStep);
    //             } else {
    //                 toast.error(addresume?.data?.Message, { theme: "colored", autoClose: 8000 });
    //             }
    
    //             props.candidate.resumeData = undefined;
    //             setError(false);
    //             window.scrollTo({ top: 0, behavior: 'smooth' });
    
    //         } catch (error) {
    //             console.error('Error occurred while calling API:', error);
    //             setError(true);
    //         }
    //     } else {
    //         setError(true);
    //     }
    // };
    
    const prevStep = () => {
        const previousStep = step - 1;
        setStep(previousStep);
        localStorage.setItem('currentStep', previousStep);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    // ===============final upload resume Data ==================

    const handleDownloadPdf = () => {
        const token = localStorage.getItem('token');
        if (validateSkill()) {
            props.requestCandidateResume({
                data: {
                    userIp: ipAddress,
                    username: data.username,
                    resume_file: resume,
                    // educations: education,
                    // works: experience,
                    // languages: languages,
                    skills: modifyskillData,
                },
                token: token
            });
            let addresume = props.candidate.resumeData;

            if (addresume !== undefined) {
                if (addresume?.data?.Status === "Success") {
                    toast.success(addresume?.data?.Message, { theme: "colored", autoClose: 8000, })
                    props.candidate.resumeData = undefined;
                    localStorage.removeItem('education');
                    localStorage.removeItem('educationalLevel');
                    localStorage.removeItem('experience');
                    localStorage.removeItem('experienceSector')
                    localStorage.removeItem('languages');
                    localStorage.removeItem('skills');
                    localStorage.removeItem('currentStep');
                    navigate("/candidate/thanks");
                } else {
                    toast.error(addresume?.data?.Message, { theme: "colored" })
                    props.candidate.resumeData = undefined;
                }
            }
            setError(false);
        } else {
            setError(true);
        }
    };

    switch (step) {
        case 1:
            return <EducationForm
                nextStep={() => handleNextStep('education')}
                education={education}
                setEducation={setEducation}
                erroreducation={erroreducation}
                sortedCountryData={sortedCountryData}
                municipalities={municipalities}
                validateEducationLevel={validateEducationLevel}
                // validateSubEducationLevel={validateSubEducationLevel}
                // validateEducationStudysector={validateEducationStudysector}
                validateEducationInstituteName={validateEducationInstituteName}
                validateEducationYearFrom={validateEducationYearFrom}
                validateEducationYearTo={validateEducationYearTo}
                validateEducationCountry={validateEducationCountry}
                validateEducationCommon={validateEducationCommon}
                validateEducationProvince={validateEducationProvince}
                handleInputEducation={handleInputEducation}
                handleSubsubmenuChange={handleSubsubmenuChange}
                handleRemoveEducation={handleRemoveEducation}
                handleChangeEducationCity={handleChangeEducationCity}
                handleAddEducation={handleAddEducation}
                setErroreducation={setErroreducation}
                FontAwesomeIcon={FontAwesomeIcon}
                faTrashCan={faTrashCan}
                isLoading={isLoading}
                isClearable={isClearable}
                isSearchable={isSearchable}
                filterConfig={filterConfig}
                mystyle={mystyle}
                focusRefs={focusRefs}
                error={error}
                setError={setError}
                showNavButtons={showNavButtons}
                selectedOptionsEducation={selectedOptionsEducation}
                t={t}
                handleChangeEducationLevel={handleChangeEducationLevel}
                educationLevels={educationLevels}
                subSubmenuOptions={subSubmenuOptions}
            />;

        case 2:
            return <ExperienceForm
                nextStep={() => handleNextStep('experience')}
                prevStep={prevStep}
                experience={experience}
                setExperience={setExperience}
                errorexperience={errorexperience}
                sortedCountryData={sortedCountryData}
                municipalities={municipalities}
                validateExperienceSector={validateExperienceSector}
                validateExperienceRole={validateExperienceRole}
                validateExperienceCountry={validateExperienceCountry}
                validateExperienceCommon={validateExperienceCommon}
                validateExperienceProvince={validateExperienceProvince}
                validateExperienceCompany={validateExperienceCompany}
                validateExperienceYearFrom={validateExperienceYearFrom}
                validateExperienceYearTo={validateExperienceYearTo}
                validateExperienceLetter={validateExperienceLetter}
                // validateExperienceDescription={validateExperienceDescription}
                handleInputExperience={handleInputExperience}
                handleSubMenuExpChange={handleSubMenuExpChange}
                handleInputExperience1={handleInputExperience1}
                handleRemoveExperience={handleRemoveExperience}
                handleChangeExprienceCity={handleChangeExprienceCity}
                handleAddExperience={handleAddExperience}
                FontAwesomeIcon={FontAwesomeIcon}
                faTrashCan={faTrashCan}
                isLoading={isLoading}
                isClearable={isClearable}
                isSearchable={isSearchable}
                filterConfig={filterConfig}
                mystyle={mystyle}
                focusRefs={focusRefs}
                error={error}
                setError={setError}
                showNavButtons={showNavButtons}
                selectedOptionsExperience={selectedOptionsExperience}
                t={t}
                handleChangeSectorLevel={handleChangeSectorLevel}
                sectorsLevel={sectorsLevel}
                subSubSectorsLevel={subSubSectorsLevel}
            />;
        case 3:
            return <LanguageForm
                nextStep={() => handleNextStep('languages')}
                prevStep={prevStep}
                languages={languages}
                errorlanguages={errorlanguages}
                validateLevelListening={validateLevelListening}
                validateLevelReading={validateLevelReading}
                validateLevelWritten={validateLevelWritten}
                validateLevelSpoken={validateLevelSpoken}
                handleInputLanguage={handleInputLanguage}
                handleChangeLanguage={handleChangeLanguage}
                handleRemoveLanguage={handleRemoveLanguage}
                handleAddLanguages={handleAddLanguages}
                // selectedOptionsLanguage={selectedOptionsLanguage}
                mystyle={mystyle}
                isClearable={isClearable}
                FontAwesomeIcon={FontAwesomeIcon}
                faTrashCan={faTrashCan}
                focusRefs={focusRefs}
                error={error}
                setError={setError}
                showNavButtons={showNavButtons}
                t={t}
            />;

        case 4:
            return <SkillForm
                prevStep={prevStep}
                skillData={skillData}
                selectedSkills={selectedSkills}
                handleChangeSkill={handleChangeSkill}
                handleCreateSkillOption={handleCreateSkillOption}
                validateSkill={validateSkill}
                isClearable={isClearable}
                errorskill={errorskill}
                mystyle={mystyle}
                skillRef={skillRef}
                error={error}
                setError={setError}
                showNavButtons={showNavButtons}
                errorResume_upload={errorResume_upload}
                resumeUploadRef={resumeUploadRef}
                validateResume_upload={validateResume_upload}
                handleChangeResume={handleChangeResume}
                handleDownloadPdf={handleDownloadPdf}
                t={t}
            />
        default:
            return null;
    }
};


const mapStateToProps = (state) => {
    return { candidate: state?.candidate };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ requestGetList, requestCandidateResume, requestGetEducationLevelList, requestGetSectorList, userLogout }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StepForm);
