import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import Breadcrumbs from "../Components/Breadcrumbs";
import Footer from "../Components/Footer";
import FormBuilder from "../Components/FormBuilder";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { requestCompanyDetails } from "../Redux/actions";

const FIELDS = [
  {
    label: "Nome con cui presentarsi",
    name: "display_name",
    type: "text",
  },
  {
    label: "Codice Fiscale o Partita IVA",
    name: "fiscal_code",
    type: "text",
  },
  {
    label: "Da che anno svolgi la tua attività?",
    name: "activity_year",
    type: "number",
  },
];

const DisplayName = (props) => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  const handleSubmit = (formData) => {
    if (
      searchParams.get("type") === "professional" ||
      searchParams.get("type") === "artisan"
    ) {
      let previousData = formData;
      if (!Object.keys(previousData).length) {
        previousData = {
          ...props.company?.getCompanyData?.data?.Message?.user,
        };
      }
      navigate(
        `/professional-requirements?type=${searchParams.get(
          "type"
        )}&registering=true`,
        {
          state: {
            formData: {
              ...formData,
              ...location.state?.formData,
            },
          },
        }
      );
    }
  };

  useEffect(() => {
    const token = props.candidate?.loginData?.data.Message.access;
    if (token) {
      props.requestCompanyDetails({ data: { token } });
    }
  }, []);

  return (
    <div className="min-vh-100 d-flex flex-column">
      <Header />
      <Breadcrumbs />
      <div className="container mt-4 h-100">
        <div className="h-100">
          <div className="seekng_job_title mb-4">
            <h2>Alcune informazioni su di te</h2>
          </div>
          <FormBuilder
            fields={FIELDS}
            onSubmit={handleSubmit}
            submitLabel="Avanti"
            onBack={() => navigate(-1)}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return { company: state.company, candidate: state.candidate };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ requestCompanyDetails }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DisplayName);
