// File: /src/utils/translations.js
// Created Date: Tuesday January 14th 2025
// Author: Christian Nonis <alch.infoemail@gmail.com>
// -----
// Last Modified: Tuesday January 14th 2025 12:22:38 pm
// Modified By: the developer formerly known as Christian Nonis at <alch.infoemail@gmail.com>
// -----

import { useTranslation } from "react-i18next";

export const useGetTranslationOr = () => {
  const { t } = useTranslation();
  return (key, or) => {
    const translation = t(key);
    return translation === key ? or : translation;
  };
};
